<template>
  <div style="height: calc(100% - 90px);">
    <div style="height: 100%; overflow-y: auto;" class="slim-scrollbar">
      <table>
        <thead>
          <tr>
            <th class="cursor-pointer" v-on:click="updateSort('domain')">
              Ref Domain
              <SortCaret v-bind:currentColumn="'domain'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('parent_domain')">
              Parent Domain
              <SortCaret v-bind:currentColumn="'parent_domain'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('domain_category')">
              Category
              <SortCaret v-bind:currentColumn="'domain_category'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('rating')">
              Rating
              <SortCaret v-bind:currentColumn="'rating'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('linked_domains')">
              Linked Domains
              <SortCaret v-bind:currentColumn="'linked_domains'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('traffic')">
              Traffic
              <SortCaret v-bind:currentColumn="'traffic'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('serp_domains.rating::decimal/NULLIF(linked_domains, 0)')">
              DR/LD
              <SortCaret v-bind:currentColumn="'serp_domains.rating::decimal/NULLIF(linked_domains, 0)'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('price')">
              Price
              <SortCaret v-bind:currentColumn="'price'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
          </tr>
          <tr>
            <th>
              <input v-model="filters.domain" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <input v-model="filters.parent_domain" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <select v-model="filters.domain_category" v-on:change="fetchSerpDomains" class="input-stealthy font-bold">
                <option value="">All</option>
                <option value="Affiliate">Affiliate</option>
                <option value="Brand">Brand</option>
                <option value="Unknown">Unknown</option>
              </select>
            </th>
            <th>
              <input v-model="filters.rating_min" style="width: 35px" class="input-stealthy text-center" placeholder="0" v-on:change="fetchSerpDomains" />
              to
              <input v-model="filters.rating_max" style="width: 35px" class="input-stealthy text-center" placeholder="100" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <input v-model="filters.linked_domains_min" style="width: 35px" class="input-stealthy text-center" placeholder="0" v-on:change="fetchSerpDomains" />
              to
              <input v-model="filters.linked_domains_max" style="width: 35px" class="input-stealthy text-center" placeholder="∞" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <input v-model="filters.traffic_min" style="width: 35px" class="input-stealthy text-center" placeholder="0" v-on:change="fetchSerpDomains" />
              to
              <input v-model="filters.traffic_max" style="width: 35px" class="input-stealthy text-center" placeholder="∞" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <input v-model="filters.dr_ld_ratio_min" style="width: 35px" class="input-stealthy text-center" placeholder="0" v-on:change="fetchSerpDomains" />
              to
              <input v-model="filters.dr_ld_ratio_max" style="width: 35px" class="input-stealthy text-center" placeholder="∞" v-on:change="fetchSerpDomains" />
            </th>
            <th>
              <input v-model="filters.price_min" style="width: 35px" class="input-stealthy text-center" placeholder="0" v-on:change="fetchSerpDomains" />
              to
              <input v-model="filters.price_max" style="width: 35px" class="input-stealthy text-center" placeholder="∞" v-on:change="fetchSerpDomains" />
            </th>
          </tr>
        </thead>
        <tbody>
          <SerpDomainRow v-for="serpDomain in serpDomains" v-bind:serpDomain="serpDomain" v-bind:datasetID="datasetID" v-bind:key="serpDomain.id" />
        </tbody>
      </table>
    </div>

    <div class="my-1 text-center">
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="page == 1"
        v-on:click="previousPage()"
      >
        <i class="fa fa-backward" />
      </button>
      <div class="inline-block mx-3" v-bind:class="{ 'opacity-50': fetching }">
        <span>{{ (page - 1) * 250 + 1 }} - {{ (page - 1) * 250 + serpDomains.length }} of {{ filteredSerpDomainCount }} ({{ totalSerpDomainCount }} total)</span>
      </div>
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="serpDomains.length < 250"
        v-on:click="nextPage()"
      >
        <i class="fa fa-forward" />
      </button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');
import SerpDomainRow from './refdomains_row.vue';
import SortCaret from '../../sort_caret.vue';

export default {
  components: {
    SerpDomainRow,
    SortCaret
  },
  props: [
    "datasetID",
    "includedNgrams",
    "excludedNgrams",
    "mode"
  ],
  data: function() {
    return {
      page: 1,
      totalSerpDomainCount: 0,
      filteredSerpDomainCount: 0,
      serpDomains: [],
      selectAll: false,
      filters: {
        domain: "",
        parent_domain: "",
        traffic_min: 1000,
        linked_domains_min: 7000,
        rating_min: 10,
        rating_max: 80
      },
      sortColumn: "rating",
      sortDirection: -1,
      fetching: false,
    }
  },
  computed: {
    selectedKeywordIDs: function(){
      return this.$store.state.selectedKeywordIDs
    },
    requestParams(){
      var self = this

      var params = {
        params: {
          filters: self.filters,
          page: self.page,
          sort_column: self.sortColumn,
          sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
        },
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      }

      return params
    }
  },
  methods: {
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchSerpDomains()
    },
    previousPage(){
      this.page -= 1
      this.fetchSerpDomains()
    },
    nextPage(){
      this.page += 1
      this.fetchSerpDomains()
    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchSerpDomains()
    },
    downloadCSV(){
      var self = this
      axios.get(
        `/datasets/${ self.datasetID }/serp_domains/refdomains.csv`,
        this.requestParams
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'serp_refdomains.csv');
        document.body.appendChild(link);
        link.click();
      })
    },
    fetchSerpDomains() {
      var self = this
      self.fetching = true

      axios.get(
        `/datasets/${ self.datasetID }/serp_domains/refdomains`,
        self.requestParams
      ).then(response => {
        self.fetching = false
        self.serpDomains = response.data.serp_refdomains
        self.totalSerpDomainCount = response.data.total_serp_refdomains
        self.filteredSerpDomainCount = response.data.filtered_serp_refdomains
      })

    }
  },
  mounted() {
    var self = this
    self.fetchSerpDomains()
  },
  beforeDestroy(){
    var self = this
  }
}

</script>
