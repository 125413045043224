<template>
  <div class="section">
    <div class="section-header" style="padding-bottom: 0">
      <div class="flex">
        <div>
          Labels
        </div>
      </div>
    </div>
    <div class="flex border-b">
      <div class="p-1">
        <input placeholder="Search" class="input" v-model="searchText" style="width: 150px;" v-on:change="">
      </div>
      <div class="ml-auto p-1">
        <button class="btn btn-sm btn-primary" v-on:click="createCategory()">+</button>
      </div>
    </div>
    <div class="bg-white" style="height: calc(100% - 70px);">
      <div style="height: 100%; overflow-y: auto;" class="slim-scrollbar">
        <Category v-for="category in categories"
          :key="category.name"
          v-bind:datasetID="datasetID"
          v-bind:category="category"
          v-bind:searchText="searchText"
          v-bind:labels="labels.filter(label => label.category == category.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import _ from 'lodash'
import Category from './category.vue'

export default {
  components: {
    Category,
  },
  props: [
    "datasetID"
  ],
  data: function(){
    return {
      searchText: "",
      fetching: false,
      labels: [],
      categories: [],
      sortColumn: "total_volume",
      sortDirection: -1,
    }
  },
  methods: {
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchLabels()
    },
    createCategory(){
      var self = this
      var categoryName = prompt("Enter the category name")

      if(!self.categories.find(category => category.name == categoryName)){
        self.categories.push({
          name: categoryName,
          is_closed: false
        })
      }

      self.sortCategories()
    },
    sortCategories(){
      var self = this

      self.categories.sort(function compare(a,b) {
        if (a.name < b.name){ return -1 }
        if (a.name > b.name){ return 1 }
        return 0
      })
    },
    fetchLabels(){
      var self = this

      self.fetching = true
      axios.get(
        `/datasets/${ self.datasetID }/labels`
      ).then(response => {
        self.fetching = false
        self.labels = response.data

        var labelNames = []

        response.data.forEach(label => {
          labelNames.push(label.name)
          if(!self.categories.find(category => category.name == label.category)){
            self.categories.push({
              name: label.category,
              is_closed: false
            })
          }
        })

        self.$store.commit('setLabelNames', labelNames)
      })

      self.sortCategories()
    },
  },
  mounted() {
    var self = this

    self.fetchLabels()
    window.addEventListener("fetch-labels", self.fetchLabels)
  }
}

</script>

<style scoped>
thead th{
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
}
</style>
