<template>
  <div class="app-ui-container" style="height: calc(100vh - 90px); overflow-y: hidden;">
    <div class="flex" style="height: 100%">
      <div class="left-panel">
        <LabelPanel v-bind:datasetID="datasetID" />
      </div>
      <div class="center-panel">
        <CenterPanel v-bind:datasetID="datasetID" />
      </div>
      <div class="right-panel">
        <NgramPanel v-bind:datasetID="datasetID" />
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import _ from 'lodash';

import CenterPanel from './center_panel.vue';
import LabelPanel from './labels/panel.vue';
import KeywordsPanel from './labels/panel.vue';
import NgramPanel from './ngrams/panel.vue';

export default {
  components: {
    LabelPanel,
    CenterPanel,
    NgramPanel
  },
  props: [
    "datasetID"
  ]
}

</script>

<style scoped>
.left-panel{
  flex: 0 0 350px;
  min-width: 0px;
  height: 100%;
}

.center-panel{
  flex: 1 0 400px;
  min-width: 0px;
  height: 100%;
  margin: 0 5px;
}

.right-panel{
  flex: 0 0 475px;
  min-width: 0px;
  height: 100%;
}
</style>
