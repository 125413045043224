<template>
  <div style="height: 100%;">
    <div style="height: calc(100% - 213px); overflow-y: scroll;" class="slim-scrollbar">
      <table>
        <thead>
          <tr class="filter-icon-row">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'rank') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'rank'" />
              <RangeFilter
                v-if="activeRangeFilter == 'rank'"
                v-bind:column="'rank'"
                v-bind:min="filters.rank.min"
                v-bind:max="filters.rank.max"
              />
            </th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'avg_difficulty_cache') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'avg_difficulty_cache'" />
              <RangeFilter
                v-if="activeRangeFilter == 'avg_difficulty_cache'"
                v-bind:column="'avg_difficulty_cache'"
                v-bind:min="filters.avg_difficulty_cache.min"
                v-bind:max="filters.avg_difficulty_cache.max"
              />
            </th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'total_keywords_cache') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'total_keywords_cache'" />
              <RangeFilter
                v-if="activeRangeFilter == 'total_keywords_cache'"
                v-bind:column="'total_keywords_cache'"
                v-bind:min="filters.total_keywords_cache.min"
                v-bind:max="filters.total_keywords_cache.max"
              />
            </th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'total_volume_cache') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'total_volume_cache'" />
              <RangeFilter
                v-if="activeRangeFilter == 'total_volume_cache'"
                v-bind:column="'total_volume_cache'"
                v-bind:min="filters.total_volume_cache.min"
                v-bind:max="filters.total_volume_cache.max"
              />
            </th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'avg_cpc_cache') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'avg_cpc_cache'" />
              <RangeFilter
                v-if="activeRangeFilter == 'avg_cpc_cache'"
                v-bind:column="'avg_cpc_cache'"
                v-bind:min="filters.avg_cpc_cache.min"
                v-bind:max="filters.avg_cpc_cache.max"
              />
            </th>
            <th
              class="text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'featured_snippets_cache') }"
              >
              <i class="fa fa-filter" v-on:click="activeRangeFilter = 'featured_snippets_cache'" />
              <RangeFilter
                v-if="activeRangeFilter == 'featured_snippets_cache'"
                v-bind:column="'featured_snippets_cache'"
                v-bind:min="filters.featured_snippets_cache.min"
                v-bind:max="filters.featured_snippets_cache.max"
              />
            </th>
          </tr>
          <tr>
            <th style="width: 30px" class="text-center cursor-pointer">
              <i class="text-green-500 far fa-check-circle" v-if="selectAll" v-on:click="deselectAllKeywordIDs()" />
              <i class="text-green-500 far fa-circle" v-else="selectAll" v-on:click="selectAllKeywordIDs()" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('keyword_text')">
              Keyword
              <SortCaret v-bind:currentColumn="'keyword_text'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th>
              Coll
            </th>
            <th>
              Page
            </th>
            <th
              style="width: 40px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'rank'), 'active-range-filter': hasRangeFilters('rank') }"
              v-on:click="updateSort('rank')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'rank'">
              RK
              <SortCaret v-bind:currentColumn="'rank'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'avg_difficulty_cache'), 'active-range-filter': hasRangeFilters('avg_difficulty_cache') }"
              v-on:click="updateSort('avg_difficulty_cache')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'avg_difficulty_cache'">
              DF
              <SortCaret v-bind:currentColumn="'avg_difficulty_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'total_keywords_cache'), 'active-range-filter': hasRangeFilters('total_keywords_cache') }"
              v-on:click="updateSort('total_keywords_cache')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'total_keywords_cache'">
              KW
              <SortCaret v-bind:currentColumn="'total_keywords_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 40px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'total_volume_cache'), 'active-range-filter': hasRangeFilters('total_volume_cache') }"
              v-on:click="updateSort('total_volume_cache')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'total_volume_cache'">
              VO
              <SortCaret v-bind:currentColumn="'total_volume_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 40px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'avg_cpc_cache'), 'active-range-filter': hasRangeFilters('avg_cpc_cache') }"
              v-on:click="updateSort('avg_cpc_cache')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'avg_cpc_cache'">
              CPC
              <SortCaret v-bind:currentColumn="'avg_cpc_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-bind:class="{ 'open-range-filter': (activeRangeFilter == 'featured_snippets_cache'), 'active-range-filter': hasRangeFilters('featured_snippets_cache') }"
              v-on:click="updateSort('featured_snippets_cache')"
              v-on:contextmenu.stop.prevent="activeRangeFilter = 'featured_snippets_cache'">
              FS
              <SortCaret v-bind:currentColumn="'featured_snippets_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
          </tr>
        </thead>
        <ParentKeywordRow
          v-for="keyword in keywords"
          v-bind:datasetID="datasetID"
          v-bind:key="keyword[0]"
          v-bind:keyword="keyword"
          v-bind:includedNgrams="includedNgrams"
          v-bind:ngrams="ngramsForKeyword(keyword)"
          v-bind:selected="selectedKeywordIDs.includes(keyword[0])"
          v-bind:class="{ 'opacity-50': fetching }"
        />
      </table>
    </div>
    <div class="my-1 text-center">
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="page == 1"
        v-on:click="previousPage()"
      >
        <i class="fa fa-backward" />
      </button>
      <div class="inline-block mx-3" v-bind:class="{ 'opacity-50': fetching }">
        <span>{{ (page - 1) * 250 + 1 }} - {{ (page - 1) * 250 + keywords.length }} of {{ keywordCount }}</span>
        <span v-if="blockedKeywordCount > 0">({{ blockedKeywordCount }} blocked)</span>
      </div>
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="keywords.length < 250"
        v-on:click="nextPage()"
      >
        <i class="fa fa-forward" />
      </button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');
import ParentKeywordRow from './row.vue';
import SortCaret from '../../sort_caret.vue';
import RangeFilter from '../range_filter.vue';

export default {
  components: {
    ParentKeywordRow,
    SortCaret,
    RangeFilter,
  },
  props: [
    "datasetID",
    "includedNgrams",
    "excludedNgrams",
    "mode"
  ],
  data: function() {
    return {
      page: 1,
      keywordCount: 0,
      blockedKeywordCount: 0,
      keywords: [],
      selectAll: false,
      sortColumn: "total_volume_cache",
      sortDirection: -1,
      fetching: false,
      activeRangeFilter: null,
      filters: {
        show_labelled_keywords: false,
        rank: { min: "", max: "" },
        total_keywords_cache: { min: "", max: "" },
        total_volume_cache: { min: "", max: "" },
        avg_cpc_cache: { min: "", max: "" },
        featured_snippets_cache: { min: "", max: "" },
        avg_difficulty_cache: { min: "", max: "" },
      }
    }
  },
  computed: {
    selectedKeywordIDs: function(){
      return this.$store.state.selectedKeywordIDs
    },
  },
  methods: {
    previousPage(){
      this.page -= 1
      this.fetchKeywords()
    },
    nextPage(){
      this.page += 1
      this.fetchKeywords()
    },
    hasRangeFilters(column){
      return this.filters[column].min.length > 0 || this.filters[column].max.length > 0
    },
    ngramsForKeyword(keyword){
      var self = this

      return keyword[9].map(ngram_id => {
        return {
          id: ngram_id,
          text: self.$options.ngramCache[ngram_id]
        }
      })
    },
    selectAllKeywordIDs(){
      var self = this

      axios.get(
        `/datasets/${ self.datasetID }/keywords/select_all`,
        {
          params: {
            mode: self.mode,
            page: self.page,
            filters: self.filters,
            'included_ngram_ids[]': self.includedNgrams.map(ngram => ngram.id),
            'excluded_ngram_ids[]': self.excludedNgrams.map(ngram => ngram.id),
            sort_column: self.sortColumn,
            sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
          },
          paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
          }
        }
      ).then(response => {
        self.$store.commit('setSelectedKeywordIDs', response.data.keyword_ids)
        self.selectAll = true

        var event = new CustomEvent("set-selected-page-name", { detail: { pageName: response.data.highest_volume_ag_keyword_text } });
        window.dispatchEvent(event);
      })
    },
    deselectAllKeywordIDs() {
      var self = this

      self.$store.commit('setSelectedKeywordIDs', [])
      self.selectAll = false

      var event = new CustomEvent("set-selected-page-name", { detail: { pageName: "" } });
      window.dispatchEvent(event);
    },
    getHighestVolumeAGText(){
      var self = this

      // This is a POST request because of size limit issue (can be thousands of KW ids)
      axios.post(
        `/datasets/${ self.datasetID }/keywords/highest_volume_keyword_text`,
        {
          keyword_ids: self.selectedKeywordIDs
        }
      ).then(response => {
        var event = new CustomEvent("set-selected-page-name", { detail: { pageName: response.data.highest_volume_keyword_text } });
        window.dispatchEvent(event);
      })

    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchKeywords()
    },
    fetchKeywords(){
      var self = this

      var params = {
        params: {
          mode: self.mode,
          page: self.page,
          filters: self.filters,
          'included_ngram_ids[]': self.includedNgrams.map(ngram => ngram.id),
          'excluded_ngram_ids[]': self.excludedNgrams.map(ngram => ngram.id),
          sort_column: self.sortColumn,
          sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
        },
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      }

      self.fetching = true
      axios.get(
        `/datasets/${ self.datasetID }/keywords/parents`,
        params
      ).then(response => {
        self.fetching = false
        self.keywords = response.data.keywords

        var ngramCache = {}

        // Ngram data is sent as an array like so
        //
        // 0 => id
        // 1 => text

        response.data.ngrams.forEach(ngram => {
          ngramCache[ngram[0]] = ngram[1]
        })

        self.$options.ngramCache = ngramCache

        self.$store.commit('setActiveNgramIDs', response.data.ngrams.map(x => x[0]))
        window.dispatchEvent(new CustomEvent("update-active-ngrams"))

        axios.get(
          `/datasets/${ self.datasetID }/keywords/parent_counts`,
          params
        ).then(response => {
          self.keywordCount = response.data.count
          self.blockedKeywordCount = response.data.blocked_count
        })
      })
    },
    handleCenterPanelModeChange(e){
      var self = this

      self.page = 1
      self.fetchKeywords()
    },
    handleKeywordSelection(e){
      var self = this

      var i = self.selectedKeywordIDs.indexOf(e.detail.keywordID)

      if(i == -1){
        self.$store.commit('addSelectedKeywordID', e.detail.keywordID)
      }else{
        self.$store.commit('removeSelectedKeywordID', i)
        self.selectAll = false
      }

      self.$store.commit('setSelectedKeywordIDs', self.selectedKeywordIDs)

      self.getHighestVolumeAGText()
    },
    handleSearchFilterUpdate(e){
      var self = this

      self.filters.include_text = e.detail.includeText
      self.filters.include_mode = e.detail.includeMode
      self.filters.exclude_text = e.detail.excludeText
      self.filters.exclude_mode = e.detail.excludeMode
      self.filters.show_labelled_keywords = e.detail.showLabelledKeywords

      self.page = 1
      self.fetchKeywords()
    },
    handleRangeFilterUpdate(e){
      var self = this

      self.filters[e.detail.column].min = e.detail.min
      self.filters[e.detail.column].max = e.detail.max
      self.activeRangeFilter = null

      self.page = 1
      self.fetchKeywords()
    },
    handleFilterReset(){
      var self = this

      self.filters.rank.min = ""
      self.filters.rank.max = ""
      self.filters.total_keywords_cache.min = ""
      self.filters.total_keywords_cache.max = ""
      self.filters.total_volume_cache.min = ""
      self.filters.total_volume_cache.max = ""
      self.filters.avg_cpc_cache.min = ""
      self.filters.avg_cpc_cache.max = ""
      self.filters.featured_snippets_cache.min = ""
      self.filters.featured_snippets_cache.max = ""
      self.filters.avg_difficulty_cache.min = ""
      self.filters.avg_difficulty_cache.max = ""
    }
  },
  mounted() {
    var self = this

    self.fetchKeywords()

    window.addEventListener("toggle-keyword-selection", self.handleKeywordSelection)
    window.addEventListener("center-panel-mode-change", self.handleCenterPanelModeChange)
    window.addEventListener("keyword-search-filter-update", self.handleSearchFilterUpdate)
    window.addEventListener("range-filter-update", self.handleRangeFilterUpdate)
    window.addEventListener("fetch-keywords", self.fetchKeywords)
    window.addEventListener("reset-all-filters", self.handleFilterReset)
  },
  beforeDestroy(){
    var self = this

    window.removeEventListener("toggle-keyword-selection", self.handleKeywordSelection)
    window.removeEventListener("center-panel-mode-change", self.handleCenterPanelModeChange)
    window.removeEventListener("keyword-search-filter-update", self.handleSearchFilterUpdate)
    window.removeEventListener("range-filter-update", self.handleRangeFilterUpdate)
    window.removeEventListener("fetch-keywords", self.fetchKeywords)
    window.removeEventListener("reset-all-filters", self.handleFilterReset)
  }
}

</script>

<style scoped>
thead th{
  top: 0;
  background: white;
}

thead th.open-range-filter{
  background: #edf2f7;
}

thead th.active-range-filter{
  background: #90cdf4;
  color: #2c5282 !important;
}

thead tr.filter-icon-row th{
  border: none;
  font-size: 8px;
}
</style>
