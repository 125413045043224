<template>
  <div id="global-search" class="p-1">
    <div id="global-search-results" class="absolute bg-white border shadow" style="width: 250px; z-index: 11199" v-if="showResults">
      <div class="p-1" v-for="result in results">
        <a v-bind:href="result.url">
          {{ result.text }}
          <div style="font-size: 9px" class="font-medium uppercase">{{ result.type }}</div>
        </a>
      </div>
      <div class="p-1" v-if="!loading && results.length == 0">
        <div class="text-gray-500">No Results</div>
      </div>
      <div class="p-1" v-if="loading">
        <div class="text-gray-500">Loading...</div>
      </div>
    </div>
    <input type="text" style="padding: 5px" placeholder="Search" class="w-full" v-model="query" v-on:input="startSearch()" v-on:focus="focused = true" v-on:blur="focused = false" />
  </div>
</template>

<script>
const axios = require('axios');
import _ from 'lodash'

export default {
  data: function(){
    return {
      query: "",
      searchTimeout: null,
      results: [],
      focused: false,
      loading: false,
    }
  },
  computed: {
    showResults(){
      return (this.results.length > 0 || this.query.length > 0)
    }
  },
  methods: {
    startSearch(){
      var self = this

      if(self.searchTimeout){
        window.clearTimeout(self.searchTimeout)
      }

      if(self.query.length == 0){
        self.results = []
        return
      }

      self.searchTimeout = window.setTimeout(function(){
        self.search()
      }, 200)
    },
    search() {
      var self = this

      self.loading = true
      axios.get('/global_search', {
        params: {
          query: self.query
        }
      }).then(function(response){
        var newResults = []
        self.loading = false

        response.data.companies.forEach(function(company){
          newResults.push({
            text: company.name,
            url: `/companies/${ company.id }`,
            type: 'Company'
          })
        })

        response.data.projects.forEach(function(project){
          newResults.push({
            text: project.full_name,
            url: `/projects/${ project.id }`,
            type: 'Project'
          })
        })

        response.data.datasets.forEach(function(dataset){
          newResults.push({
            text: dataset.name,
            url: `/datasets/${ dataset.id }`,
            type: 'Dataset'
          })
        })

        self.results = newResults
      })
    }
  }
}

</script>
