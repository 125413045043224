<template>
  <div style="height: calc(100% - 90px);">
    <div style="height: 100%; overflow-y: auto;" class="slim-scrollbar">
      <div class="p-1">
        <button type="button" class="btn btn-sm btn-dark" v-on:click="downloadCSV">Download CSV</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Collection</th>
            <th>Page</th>
            <th class="cursor-pointer">
              Domain
              <SortCaret v-bind:currentColumn="'serp_domains.name'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('title')">
              Title
              <SortCaret v-bind:currentColumn="'title'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('link')">
              URL
              <SortCaret v-bind:currentColumn="'link'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('date')">
              Age
              <SortCaret v-bind:currentColumn="'date'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('position')">
              Position
              <SortCaret v-bind:currentColumn="'position'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
          </tr>
          <tr>
            <th>
              <input v-model="filters.collection_name" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerps" />
            </th>
            <th>
              <input v-model="filters.article_name" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerps" />
            </th>
            <th>
              <input v-model="filters.domain_name" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerps" />
            </th>
            <th>
              <input v-model="filters.title" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerps" />
            </th>
            <th>
              <input v-model="filters.link" style="width: 100%" class="input-stealthy" placeholder="Search" v-on:change="fetchSerps" />
            </th>
            <th>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <SerpRow v-for="serp in serps" v-bind:serp="serp" v-bind:key="serp.id" />
        </tbody>
      </table>
    </div>

    <div class="my-1 text-center">
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="page == 1"
        v-on:click="previousPage()"
      >
        <i class="fa fa-backward" />
      </button>
      <div class="inline-block mx-3" v-bind:class="{ 'opacity-50': fetching }">
        <span>{{ (page - 1) * 250 + 1 }} - {{ (page - 1) * 250 + serps.length }} of {{ filteredSerpCount }} ({{ totalSerpCount }} total)</span>
      </div>
      <button class="btn btn-sm btn-primary text-center rounded-l-full"
        v-bind:disabled="serps.length < 250"
        v-on:click="nextPage()"
      >
        <i class="fa fa-forward" />
      </button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');
import SerpRow from './row.vue';
import SortCaret from '../../sort_caret.vue';

export default {
  components: {
    SerpRow,
    SortCaret
  },
  props: [
    "datasetID",
    "includedNgrams",
    "excludedNgrams",
    "mode"
  ],
  data: function() {
    return {
      page: 1,
      totalSerpCount: 0,
      filteredSerpCount: 0,
      serps: [],
      selectAll: false,
      sortColumn: "title",
      sortDirection: 1,
      fetching: false,
      filters: {},
    }
  },
  computed: {
    selectedKeywordIDs: function(){
      return this.$store.state.selectedKeywordIDs
    },
    requestParams(){
      var self = this

      var params = {
        params: {
          filters: self.filters,
          page: self.page,
          sort_column: self.sortColumn,
          sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
        },
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      }

      return params
    }
  },
  methods: {
    previousPage(){
      this.page -= 1
      this.fetchSerps()
    },
    nextPage(){
      this.page += 1
      this.fetchSerps()
    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchSerps()
    },
    downloadCSV(){
      var self = this
      axios.get(
        `/datasets/${ self.datasetID }/serps.csv`,
        this.requestParams
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'serps.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    },
    fetchSerps() {
      var self = this
      self.fetching = true
      axios.get(
        `/datasets/${ self.datasetID }/serps.json`,
        self.requestParams
      ).then(response => {
        self.fetching = false
        self.serps = response.data.serps
        self.totalSerpCount = response.data.total_serps
        self.filteredSerpCount = response.data.filtered_serps
      })

    }
  },
  mounted() {
    var self = this
    self.fetchSerps()
  },
  beforeDestroy(){
    var self = this
  }
}

</script>
