<template>
  <div>
    <div class="p-1 font-bold">{{ category.name }}</div>
    <div class="p-1">
      <input type="text" v-model="newLabelText" class="input" placeholder="Create Label" v-on:change="createLabel()" v-on:paste="handlePaste" />
    </div>
    <table>
      <thead>
        <th class="cursor-pointer" v-on:click="updateSort('name')">
          Label
          <SortCaret v-bind:currentColumn="'name'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th style="width: 34px" class="cursor-pointer text-center" v-on:click="updateSort('avg_difficulty_cache')">
          DF
          <SortCaret v-bind:currentColumn="'avg_difficulty_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th style="width: 40px" class="cursor-pointer text-center" v-on:click="updateSort('total_keywords_cache')">
          KW
          <SortCaret v-bind:currentColumn="'total_keywords_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th style="width: 40px" class="cursor-pointer text-center" v-on:click="updateSort('total_volume_cache')">
          VO
          <SortCaret v-bind:currentColumn="'total_volume_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th style="width: 39px" class="cursor-pointer text-center" v-on:click="updateSort('avg_cpc_cache')">
          CPC
          <SortCaret v-bind:currentColumn="'avg_cpc_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th style="width: 34px" class="cursor-pointer text-center" v-on:click="updateSort('featured_snippets_cache')">
          FS
          <SortCaret v-bind:currentColumn="'featured_snippets_cache'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
        </th>
        <th></th>
      </thead>
      <LabelRow
        v-for="label in sortedLabels"
        :key="label.id"
        v-bind:label="label"
        v-bind:datasetID="datasetID"
        v-if="searchText.length == 0 || label.name.toLowerCase().includes(searchText.toLowerCase())"
      />
    </table>
  </div>
</template>

<script>
const axios = require('axios');
import _ from 'lodash';
import minifyNumberFn from '../../../mithril/minify_number.js';
import SortCaret from '../../sort_caret.vue';
import LabelRow from './row.vue';

export default {
  components: {
    SortCaret,
    LabelRow,
  },
  props: [
    "searchText",
    "datasetID",
    "category",
    "labels"
  ],
  data: function(){
    return {
      sortColumn: "name",
      sortDirection: 1,
      newLabelText: ""
    }
  },
  computed: {
    sortedLabels(){
      var self = this

      var result =  _.orderBy(self.labels, self.sortColumn )

      if(self.sortDirection == -1){
        return result.reverse()
      }else{
        return result
      }
    }
  },
  methods: {
    createLabel(){
      var self = this
      axios.post(
        `/datasets/${ self.datasetID }/labels`,
        {
          label: {
            name: self.newLabelText,
            category: self.category.name
          }
        }
      ).then(response => {
        self.newLabelText = ""
      })
    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }
    },
    handlePaste(e){
      var self = this

      var paste = (e.clipboardData || window.clipboardData).getData('text')

      axios.post(
        `/datasets/${ self.datasetID }/labels/create_bulk`,
        {
          label_names: paste,
          category: self.category.name
        }
      ).then(response => {
        self.newLabelText = ""
      })

      return false
    }
  }
}
</script>
