<template>
  <div>
    <div class="modal-overlay" v-on:click="closeModal()">
      <div class="modal-container p-2" style="width: 1000px;" v-on:click.stop.prevent>
        <div>
          <div>Ignore Ngrams (one per line)</div>
        </div>
        <div>
          <textarea autofocus v-model="ngramList" style="width: 100%; height: 250px" />
        </div>
        <div class="p-1"><button class="btn btn-sm btn-primary" v-on:click="bulkIgnore">Ignore</button></div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default{
  props: [
    'datasetID'
  ],
  data: () => {
    return {
      ngramList: "",
    }
  },
  methods: {
    bulkIgnore(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/ignore_bulk`, {
        ngram_text: self.ngramList
      })

      self.closeModal()
    },
    closeModal(){
      var event = new CustomEvent("close-bulk-ignore-ngrams-modal");
      window.dispatchEvent(event);
    },
  },
  mounted(){
  }
}
</script>
