<template>
  <div id="content-manager-container" class="app-ui-container bg-white border">
    <table>
      <thead>
        <tr>
          <th><input class="input" placeholder="Filter" v-model="collectionNameFilter" /></th>
          <th><input class="input" placeholder="Filter" v-model="articleNameFilter" /></th>
          <th>
            <select class="input" v-model="articleStatusFilter">
              <option value="">All</option>
              <option value="in_progress">In Progress</option>
              <option value="under_review">Under Review</option>
              <option value="published">Published</option>
            </select>
          </th>
          <th colspan="8"></th>
        </tr>
        <tr>
          <th class="cursor-pointer" style="min-width: 125px" v-on:click="updateSort('collection_name')">Collection</th>
          <th class="cursor-pointer" style="min-width: 150px" v-on:click="updateSort('name')">Name</th>
          <th class="cursor-pointer" v-on:click="updateSort('status')">Status</th>
          <th>Assignee</th>
          <th class="cursor-pointer" v-on:click="updateSort('quality_checks_length')">Quality</th>
          <th class="w-full">Article</th>
          <th class="cursor-pointer" style="width: 50px" v-on:click="updateSort('keywords_count')">KW</th>
          <th class="cursor-pointer" style="width: 50px" v-on:click="updateSort('total_volume')">VO</th>
          <th class="cursor-pointer" style="width: 50px" v-on:click="updateSort('avg_cpc')">CPC</th>
          <th class="cursor-pointer" style="width: 50px" v-on:click="updateSort('avg_difficulty')">DF</th>
          <th class="cursor-pointer" style="width: 50px" v-on:click="updateSort('featured_snippets')">FS</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="article in filteredArticles" :key="article.id">

          <td class="whitespace-no-wrap">{{ collectionName(article) }}</td>
          <td class="whitespace-no-wrap">{{ article.name }}</td>
          <td class="whitespace-no-wrap">
            <select v-model="article.aasm_state" v-on:change="updateArticle(article)" v-bind:class="{
                'p-0': true,
                'text-gray-800 bg-gray-200 border border-gray-400': (article.aasm_state == 'in_progress'),
                'text-yellow-800 bg-yellow-200 border border-yellow-400': (article.aasm_state == 'under_review'),
                'text-green-800 bg-green-200 border border-green-400': (article.aasm_state == 'published'),
              }">
              <option class="bg-white" value="in_progress">In Progress</option>
              <option class="bg-white" value="under_review">Under Review</option>
              <option class="bg-white" value="published">Published</option>
            </select>
          </td>
          <td class="whitespace-no-wrap">
            <ArticleAssignees v-bind:article="article" v-bind:assignees="article.users" v-bind:users="users" />
          </td>
          <td class="whitespace-no-wrap">
            {{ article.article_quality_checks.length }}/{{ totalQualityCheckCount }}
          </td>
          <td><button class="btn btn-sm btn-primary" v-on:click="openArticle(article)">Edit</button></td>
          <td class="text-center">{{ minifyNumber(article.keywords_count) }}</td>
          <td class="text-center">{{ minifyNumber(article.total_volume) }}</td>
          <td class="text-center">{{ article.avg_cpc.toFixed(1) }}</td>
          <td class="text-center">{{ article.avg_difficulty }}</td>
          <td class="text-center">{{ minifyNumber(article.featured_snippets) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import minifyNumberFn from '../../mithril/minify_number.js';
import ArticleAssignees from './assignees.vue';

const axios = require('axios');
import _ from 'lodash'

export default {
  props: ['totalQualityCheckCount'],
  components: {
    ArticleAssignees
  },
  computed: {
    filteredArticles(){
      var self = this

      return self.articles.filter(article => {
        var passAllFilters = true

        if(self.collectionNameFilter.length > 0){
          passAllFilters = passAllFilters && self.collectionName(article).toLowerCase().includes(self.collectionNameFilter.toLowerCase())
        }

        if(self.articleNameFilter.length > 0){
          passAllFilters = passAllFilters && article.name.toLowerCase().includes(self.articleNameFilter.toLowerCase())
        }

        if(self.articleStatusFilter.length > 0){
          passAllFilters = passAllFilters && article.aasm_state == self.articleStatusFilter
        }

        return passAllFilters
      })
    },
  },
  methods: {
    openArticle(article){
      window.open(`/datasets/${ this.datasetID }/articles/${ article.id }/edit`)
    },
    collectionName(article){
      return (article.collection ? article.collection.name : '')
    },
    statusText(article){
      if(article.aasm_state == "in_progress"){
        return "In Progress"
      }else if(article.aasm_state == "under_review"){
        return "Under Review"
      }else if(article.aasm_state == "published"){
        return "Published"
      }
    },
    minifyNumber(n){
      return minifyNumberFn(n)
    },
    updateArticle(article){
      var self = this

      axios.patch(
        `/datasets/${ self.datasetID }/articles/${ article.id }.json`,
        {
          article: article
        }
      ).then(function (response) {
      })
    },
    updateSort(column){
      var self = this

      if(self.sortColumn == column){
        self.sortDirection = (self.sortDirection == "asc" ? "desc" : "asc")
      }else{
        self.sortColumn = column
        if(["keywords_count", "total_volume", "avg_cpc", "avg_difficulty", "featured_snippets"].includes(column)){
          self.sortDirection = "desc"
        }else{
          self.sortDirection = "asc"
        }
      }

      var result = _.sortBy(self.articles, function(article){
        if(self.sortColumn == 'collection_name'){
          return self.collectionName(article)
        }else if(self.sortColumn == 'quality_checks_length'){
          return article.article_quality_checks.length
        }else{
          return article[self.sortColumn]
        }
      })

      if(self.sortDirection == "asc"){
        self.articles = result
      }else{
        self.articles = _.reverse(result)
      }
    }
  },
  data: function () {
    return {
      datasetID: null,
      articles: [],
      users: [],
      sortColumn: "name",
      sortDirection: "desc",
      collectionNameFilter: "",
      articleNameFilter: "",
      articleStatusFilter: ""
    }
  },
  mounted() {
    var self = this
    self.datasetID = window.location.pathname.split('/')[2]

    axios.get(`/datasets/${ self.datasetID }/articles.json`).then(function (response) {
      response.data.forEach(article => {
        article.keywords_count = parseInt(article.keywords_count)
        article.total_volume = parseInt(article.total_volume)
        article.avg_cpc = parseFloat(article.avg_cpc)
        article.avg_difficulty = parseInt(article.avg_difficulty)
        article.featured_snippets = parseInt(article.featured_snippets)
      })

      self.articles = response.data
    })

    axios.get(`/users/for_dataset/${ self.datasetID }`).then(function (response) {
      self.users = response.data
    })
  }
}
</script>

<style scoped>
</style>
