<template>
  <span v-if="show">
    <i class="fa fa-caret-up" v-if="sortedAsc" />
    <i class="fa fa-caret-down" v-if="sortedDesc" />
  </span>
</template>

<script>
  export default {
    props: ['currentColumn', 'sortColumn', 'sortDirection'],
    computed: {
      show(){
        return this.currentColumn == this.sortColumn
      },
      sortedAsc() {
        return this.sortDirection == 1
      },
      sortedDesc() {
        return this.sortDirection == -1
      }
    }
  }
</script>
