<template>
  <div>
    <div class="flex border-b">
      <div>
        <div class="p-1">
          <input placeholder="Include" class="input" v-model="includeText" style="width: 125px;" v-on:change="broadcastUpdate()"
          /><select class="input" v-model="includeMode" style="width: 70px; border-left: none; border-radius: 0px;" v-on:change="broadcastUpdate()">
            <option value="Any">Any</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div>
        <div class="p-1">
          <input placeholder="Exclude" class="input" v-model="excludeText" style="width: 125px;" v-on:change="broadcastUpdate()"
          /><select class="input" v-model="excludeMode" style="width: 70px; border-left: none; border-radius: 0px;" v-on:change="broadcastUpdate()">
            <option value="Any">Any</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div class="ml-auto p-1">
        <button class="btn btn-sm btn-red" v-on:click="deleteSelectedKeywords()">Delete Keyword(s)</button>
      </div>
    </div>
    <div class="flex border-b">
      <div class="p-1 uppercase">
        Filters
      </div>
      <div class="p-1">
        <div v-for="ngram in includedNgrams"
          class="filter-pill"
          v-on:click="toggleNgramIncludeSelection(ngram.id, ngram.text)"
        >
          <div class="filter-pill-icon bg-indigo-500" style="width: 15px; height: 15px; line-height: 15px;">+</div>
          {{ ngram.text }}
        </div>
        <div v-for="ngram in excludedNgrams"
          class="filter-pill"
          v-on:click="toggleNgramExcludeSelection(ngram.id, ngram.text)"
        >
          <div class="filter-pill-icon bg-red-500" style="width: 15px; height: 15px; line-height: 15px;">-</div>
          {{ ngram.text }}
        </div>
      </div>
    </div>
    <div class="flex border-b p-1">
      <div>
        <label for="showLabelledKeywords" style="line-height: 25px; text-transform: none;">
          <input id="showLabelledKeywords" name="showLabelledKeywords" type="checkbox" v-model="showLabelledKeywords" v-on:change="broadcastUpdate()" />
          <span class="no-selection-highlight">Show Labelled KWs</span>
        </label>
      </div>
      <div class="ml-5">
        <button title="Vol > 500, Difficulty < 5, FS > 0" class="btn btn-sm btn-light" v-on:click="setEasyWinsFilter()">Easy Wins</button>
      </div>
      <div class="ml-1">
        <button title="who,what,where,when,why,how,can,is,does" class="btn btn-sm btn-light" v-on:click="setQuestionsFilter()">Questions</button>
      </div>
      <div class="ml-1">
        <button class="btn btn-sm btn-light" v-on:click="resetFilters()">Reset Filters</button>
      </div>
    </div>
    <div class="border-b p-1">
      <div id="#collection-inputs" class="inline-block mr-5">
        <input type="text" class="input" style="width: 100px; margin-right: 0" v-model="collection1" placeholder="Collection" />
        <input type="text" class="input text-center" style="width: 15px; border: none; margin: 0" value="/" readonly v-if="collection1.length > 0" />
        <input type="text" class="input" style="width: 100px; margin-right: 0" v-if="collection1.length > 0" v-model="collection2" />
        <input type="text" class="input text-center" style="width: 15px; border: none; margin: 0" value="/" readonly v-if="collection2.length > 0" />
        <input type="text" class="input" style="width: 100px; margin-right: 0" v-if="collection2.length > 0" v-model="collection3" placeholder="Page Name" />
      </div>
      <div class="inline-block">
        <input type="text"
          class="input"
          v-model="pageName"
          v-on:input="searchPages()" />
        <div
          v-if="pageSearchResults.length > 0"
          class="absolute bg-white slim-scrollbar"
          style="max-height: 150px; width: 150px"
        >
          <div class="p-1 cursor-pointer hover:bg-gray-200"
            v-for="pageSearchResult in pageSearchResults"
            :key="pageSearchResult.id"
            v-on:click="selectPageSearchResult(pageSearchResult)"
          >
            <div>{{ pageSearchResult.name }}</div>
            <div style="font-size: 10px" v-if="pageSearchResult.collection">{{ pageSearchResult.collection.name }}</div>
          </div>
        </div>
      </div>
      <div class="inline-block">
        <button class="btn btn-sm btn-primary inline-block ml-1" v-on:click="setArticleForSelectedKeywords()" v-bind:disabled="selectedKeywordIDs.length == 0">Set page</button>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');

export default {
  props: [
    "datasetID",
    "includedNgrams",
    "excludedNgrams",
  ],
  data: () => {
    return {
      collection1: "",
      collection2: "",
      collection3: "",
      pageName: "",
      pageSearchResults: [],
      includeText: "",
      includeMode: "Any",
      excludeText: "",
      excludeMode: "Any",
      showLabelledKeywords: true,
    }
  },
  computed: {
    selectedKeywordIDs: function(){
      return this.$store.state.selectedKeywordIDs
    },
    collectionName(){
      var self = this

      var result = []

      if(self.collection1.length > 0){
        result.push(self.collection1)
      }

      if(self.collection2.length > 0){
        result.push(self.collection2)
      }

      if(self.collection3.length > 0){
        result.push(self.collection3)
      }

      return result.join('/')
    }
  },
  methods: {
    setArticleForSelectedKeywords(){
      var self = this

      axios.post(`/datasets/${ self.datasetID }/keywords/assign_article`, {
        keyword_ids: self.selectedKeywordIDs,
        collection_name: self.collectionName,
        article_name: self.pageName
      })

      self.$store.commit('setSelectedKeywordIDs', [])
    },
    deleteSelectedKeywords(){
      var self = this

      axios.post(`/datasets/${ self.datasetID }/keywords/delete`, {
        keyword_ids: self.selectedKeywordIDs
      })

      self.$store.commit('setSelectedKeywordIDs', [])
    },
    handleSetSelectedPageName(e){
      // Make sure each word is capitalized.
      this.pageName = (e.detail.pageName || "").split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    },
    resetFilters(){
      var event = new CustomEvent("reset-all-filters");
      window.dispatchEvent(event);
    },
    setEasyWinsFilter(){
      var event = new CustomEvent("range-filter-update", {
        detail: {
          column: "total_volume_cache",
          min: "500",
          max: "",
        }
      });

      window.dispatchEvent(event);

      event = new CustomEvent("range-filter-update", {
        detail: {
          column: "avg_difficulty_cache",
          min: "",
          max: "5",
        }
      });

      window.dispatchEvent(event);

      event = new CustomEvent("range-filter-update", {
        detail: {
          column: "featured_snippets_cache",
          min: "0",
          max: "",
        }
      });

      window.dispatchEvent(event);
    },
    setQuestionsFilter(){
      this.includeText = "who ,what ,where ,when ,why ,how ,can ,is ,does "
      this.includeMode = "Any"
      this.broadcastUpdate()
    },
    broadcastUpdate: function(){
      var self = this
      var event = new CustomEvent("keyword-search-filter-update", {
        detail: {
          includeText: self.includeText,
          includeMode: self.includeMode,
          excludeText: self.excludeText,
          excludeMode: self.excludeMode,
          showLabelledKeywords: self.showLabelledKeywords,
        }
      });

      window.dispatchEvent(event);
    },
    searchPages(){
      var self = this
      axios.get(
        `/datasets/${ self.datasetID }/articles.json`,
        {
          params: {
            mode: "all",
            search: self.pageName
          },
          paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
          }
        }
      ).then(response => {
        self.pageSearchResults = response.data
      })
    },
    toggleNgramIncludeSelection(ngramID, ngramText) {
      var self = this
      var event = new CustomEvent("toggle-ngram-include-selection", { detail: { ngramID: ngramID, ngramText: ngramText } });
      window.dispatchEvent(event);
    },
    toggleNgramExcludeSelection(ngramID, ngramText) {
      var self = this
      var event = new CustomEvent("toggle-ngram-exclude-selection", { detail: { ngramID: ngramID, ngramText: ngramText } });
      window.dispatchEvent(event);
    },
    selectPageSearchResult(article){
      var self = this

      self.pageName = article.name

      if(article.collection){
        var pieces = article.collection.name.split('/')

        self.collection1 = pieces[0] || ""
        self.collection2 = pieces[1] || ""
        self.collection3 = pieces[2] || ""
      }

      self.pageSearchResults = []
    }
  },
  mounted() {
    var self = this
    window.addEventListener("set-selected-page-name", self.handleSetSelectedPageName)
    window.addEventListener("reset-all-filters", function(){
      self.includeText = ""
      self.excludeText = ""
      self.broadcastUpdate()
    })
  }
}
</script>

<style scoped>
#collection-inputs input{
  display: inline-block;
}
</style>
