<template>
  <tr>
    <td>{{ serpDomain.domain }}</td>
    <td>{{ serpDomain.refdomain_count }}</td>
    <td>
      <select v-model="serpDomain.domain_category" v-on:change="updateDomainCategory" class="input-stealthy font-bold" v-bind:style="`color: ${ colorForMode }`">
        <option value="Affiliate">Affiliate</option>
        <option value="Brand">Brand</option>
        <option value="Unknown">Unknown</option>
      </select>
    </td>
    <td>{{ serpDomain.traffic }}</td>
    <td>{{ serpDomain.rating }}</td>
    <td>{{ serpDomain.linked_domains }}</td>
    <td>{{ parseFloat(serpDomain.dr_ld_ratio).toFixed(2) }}</td>
    <td>
      <span v-if="serpDomain.price">$</span><input v-model="serpDomain.price" class="input-stealthy" v-on:change="updateDomainPrice" />
    </td>
  </tr>
</template>

<script>
const axios = require('axios');

export default {
  props: [
    "datasetID",
    "serpDomain",
  ],
  computed: {
    colorForMode(){
      switch(this.serpDomain.domain_category){
        case 'Affiliate':
          return '#00a8cc';
        case 'Brand':
          return '#EE6002';
        case 'Unknown':
          return '#555555';
      }
    }
  },
  methods: {
    updateDomainPrice(){
      var self = this

      axios.patch(
        `/datasets/${ self.datasetID }/serp_domains/${ self.serpDomain.id }`,
        {
          serp_domain: {
            price: self.serpDomain.price
          }
        }
      )
    },
    updateDomainCategory(){
      var self = this

      axios.patch(
        `/datasets/${ self.datasetID }/serp_domains/${ self.serpDomain.id }`,
        {
          serp_domain: {
            domain_category: self.serpDomain.domain_category
          }
        }
      ).then(function (response) {
        self.serpDomain.rating = response.data.rating
        self.serpDomain.linked_domains = response.data.linked_domains
        self.serpDomain.traffic = response.data.traffic
        self.serpDomain.price = response.data.price
      })
    }
  }
}
</script>
