<template>
  <tbody>
    <tr>
      <td><input type="text" class="input-stealthy" v-model="collection1" v-on:change="updateCollection(); $event.target.blur()" /></td>
      <td><input type="text" class="input-stealthy" v-model="collection2" v-on:change="updateCollection(); $event.target.blur()" /></td>
      <td><input type="text" class="input-stealthy" v-model="collection3" v-on:change="updateCollection(); $event.target.blur()" /></td>
      <td class="whitespace-no-wrap">
        <input type="text" class="input-stealthy" v-model="article.name" v-on:change="updateArticleName(); $event.target.blur()" />
        <div class="inline-block align-top px-1 cursor-pointer" v-on:click="toggleShowChildren()">
          <i class="fas fa-chevron-down" v-if="showKeywords" />
          <i class="fas fa-chevron-right" v-else />
        </div>
      </td>
      <td>
        <button v-if="article.confirmed" class="btn btn-sm btn-primary text-sm my-1" style="padding: 1px 5px;" v-on:click="unconfirm()">Unconfirm</button>
        <button v-else class="btn btn-sm btn-primary text-sm my-1" style="padding: 1px 5px;" v-on:click="confirm()">Confirm</button>
      </td>
      <td><button class="btn btn-sm btn-light text-sm my-1" style="padding: 1px 5px;" v-on:click="remove()">Remove</button></td>
      <td class="text-center">{{ parseInt(article.avg_difficulty) }}</td>
      <td class="text-center">{{ article.keywords_count }}</td>
      <td class="text-center">{{ minifyNumber(article.total_volume) }}</td>
      <td class="text-center">{{ parseFloat(article.avg_cpc).toFixed(1) }}</td>
      <td class="text-center">{{ article.featured_snippets }}</td>
    </tr>
    <tr v-if="showKeywords" v-for="keyword in keywords" style="color: #666666; font-size: 11px;">
      <td colspan="3"></td>
      <td style="padding-left: 15px">{{ keyword[1] }}</td>
      <td colspan="7">
      </td>
    </tr>
  </tbody>
</template>

<script>
const axios = require('axios');
import minifyNumberFn from '../../../mithril/minify_number.js';

export default {
  props: [
    "datasetID",
    "article",
  ],
  data: function(){
    return {
      showKeywords: false,
      keywords: [],
      editingCollection1: false,
      editingCollection2: false,
      editingCollection3: false,
      collection1: "",
      collection2: "",
      collection3: "",
    }
  },
  computed: {
    collectionName(){
      if(this.article.collection){
        return this.article.collection.name
      }else{
        return ""
      }
    }
  },
  methods: {
    toggleShowChildren() {
      var self = this

      self.showKeywords = !self.showKeywords

      if(self.showKeywords){
        self.fetchKeywords()
      }
    },
    updateCollection(){
      var self = this
      var pieces = [self.collection1, self.collection2, self.collection3].filter(x => x.length > 0)

      if(pieces.length == 0){
        return
      }

      axios.post(`/datasets/${ self.datasetID }/articles/${ self.article.id }/update_collection_name`,  {
        collection_name: pieces.join('/')
      }).then(response => {
        self.article.collection.name = response.data.collection_name
        self.extractCollectionNamePieces()
      }).catch(function (error) {
        alert("Error while saving collection name")
      })
    },
    updateArticleName(){
      var self = this

      axios.post(`/datasets/${ self.datasetID }/articles/${ self.article.id }/rename`,  {
        name: self.article.name
      }).then(response => {
        self.article.name = response.data.name
      }).catch(function (error) {
        alert("Error while saving page name")
      })
    },
    minifyNumber(x){
      return minifyNumberFn(x)
    },
    fetchKeywords(){
      var self = this
      axios.get(`/datasets/${ self.datasetID }/articles/${ self.article.id }/keywords`).then((response) => {
        self.keywords = response.data
      })
    },
    confirm(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/articles/${ self.article.id }/confirm`)
    },
    unconfirm(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/articles/${ self.article.id }/unconfirm`)
    },
    remove(){
      var self = this
      axios.delete(`/datasets/${ self.datasetID }/articles/${ self.article.id }`)
    },
    extractCollectionNamePieces(){
      var self = this
      var pieces = []

      if(self.article.collection){
        var pieces = self.article.collection.name.split('/')
      }

      self.collection1 = pieces[0] || ""
      self.collection2 = pieces[1] || ""
      self.collection3 = pieces[2] || ""
    }
  },
  mounted() {
    this.extractCollectionNamePieces()
  }
}
</script>

<style scoped>
</style>
