<template>
  <div class="app-ui-container" style="height: calc(100vh - 90px); overflow-y: hidden;">
    <div class="section">
      <div class="section-header" style="padding-bottom: 0">
        <div class="flex">
          <div>
            SERP Analysis
          </div>
          <div class="ml-auto">
            <div class="nav-tabs" v-bind:class="{ active: (mode == 'serps') }" v-on:click="setMode('serps')">
              SERPs
              <div class="subtitle">{{ serpCount }}</div>
            </div>
            <div class="nav-tabs" v-bind:class="{ active: (mode == 'domains') }" v-on:click="setMode('domains')">
              Domains
              <div class="subtitle">{{ serpDomainCount }}</div>
            </div>
            <div class="nav-tabs" v-bind:class="{ active: (mode == 'refdomains') }" v-on:click="setMode('refdomains')">
              Refdomains
              <div class="subtitle">{{ serpRefDomainCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 100%;">
        <SerpPanel
          v-if="mode == 'serps'"
          v-bind:datasetID="datasetID"
        />
        <SerpDomainPanel
          v-if="mode == 'domains'"
          v-bind:datasetID="datasetID"
        />
        <SerpRefdomainPanel
          v-if="mode == 'refdomains'"
          v-bind:datasetID="datasetID"
        />
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import _ from 'lodash';

import SerpPanel from './serps/panel.vue';
import SerpDomainPanel from './serp_domains/panel.vue';
import SerpRefdomainPanel from './serp_domains/refdomains_panel.vue';

export default {
  components: {
    SerpPanel,
    SerpDomainPanel,
    SerpRefdomainPanel,
  },
  props: [
    "datasetID",
  ],
  data(){
    return {
      mode: "serps",
      serpCount: 0,
      serpDomainCount: 0,
      serpRefDomainCount: 0,
    }
  },
  methods: {
    setMode(newMode){
      this.mode = newMode
    },
    fetchCounts(){
      var self = this

      axios.get(
        `/datasets/${ self.datasetID }/serps/counts`
      ).then(response => {
        self.serpCount = response.data.serp_count
        self.serpDomainCount = response.data.serp_domain_count
        self.serpRefDomainCount = response.data.serp_refdomain_count
      })
    }
  },
  mounted(){
    this.fetchCounts()
  }
}

</script>

<style scoped>
.left-panel{
  flex: 0 0 350px;
  min-width: 0px;
  height: 100%;
}

.center-panel{
  flex: 1 0 400px;
  min-width: 0px;
  height: 100%;
  margin: 0 5px;
}
</style>
