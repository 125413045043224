<template>
  <tbody>
    <tr>
      <td class="text-center cursor-pointer" v-on:click="toggleKeywordSelection()">
        <i class="text-green-500 far fa-check-circle" v-if="selected" />
        <i class="text-green-500 far fa-circle" v-else />
      </td>
      <td>
        <div style="margin-top: 3px" class="no-selection-highlight">
          <div class="inline-block truncate" style="max-width: 300px" v-bind:title="keywordText">
            {{ keywordText }}
          </div>
          <div class="inline-block align-top px-1 cursor-pointer" v-on:click="toggleShowChildren()">
            <i class="fas fa-chevron-down" v-if="showChildKeywords" />
            <i class="fas fa-chevron-right" v-else />
          </div>
          <div class="inline-block ml-2 whitespace-no-wrap align-top">
            <span v-for="ngram in ngrams" v-if="ngram.text"
              class="ngram-button"
              v-bind:class="{ 'bg-gray-400': !ngramLabelled(ngram.text), 'bg-green-200': ngramLabelled(ngram.text), 'bg-blue-200': ngramIncluded(ngram.id) }"
              v-on:click="handleNgramClick($event, ngram.id, ngram.text)"
              v-on:contextmenu.stop.prevent="toggleNgramExcludeSelection(ngram.id, ngram.text)"
            >{{ ngram.text }}</span>
          </div>
        </div>
      </td>
      <td>{{ collectionName }}</td>
      <td>{{ articleName }}</td>
      <td class="text-center">{{ rank }}</td>
      <td class="text-center">{{ avgDifficulty }}</td>
      <td class="text-center">{{ childKeywordCount }}</td>
      <td class="text-center">{{ totalVolume }}</td>
      <td class="text-center">{{ avgCPC }}</td>
      <td class="text-center">{{ totalFeaturedSnippets }}</td>
    </tr>
    <tr v-if="showChildKeywords" v-for="childKeyword in childKeywords" style="color: #666666; font-size: 11px;">
      <td></td>
      <td style="padding-left: 15px">{{ childKeyword.keyword_text }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td class="text-center">{{ childKeyword.difficulty }}</td>
      <td></td>
      <td class="text-center">{{ minifyNumberFn(childKeyword.volume) }}</td>
      <td class="text-center">{{ childKeyword.cpc }}</td>
      <td class="text-center">
        <i class="fa fa-check" v-if="childKeyword.has_featured_snippet" />
      </td>
    </tr>
  </tbody>
</template>

<script>
const axios = require('axios');
import minifyNumberFn from '../../../mithril/minify_number.js';

export default {
  components: {
  },
  props: [
    "keyword",
    "ngrams",
    "datasetID",
    "selected",
    "includedNgrams",
  ],
  data: function() {
    return {
      showChildKeywords: false,
      childKeywords: []
    }
  },
  computed: {
    // Keyword data is sent as an array like so
    //
    // 0 => id
    // 1 => keyword_text
    // 2 => confirmed
    // 3 => avg_difficulty_cache
    // 4 => total_keywords_cache
    // 5 => total_volume_cache
    // 6 => avg_cpc_cache
    // 7 => featured_snippets_cache
    // 8 => rank
    // 9 => ngram_signature
    // 10 => article id
    // 11 => article name
    // 12 => collection name

    keywordID(){
      return this.keyword[0]
    },

    keywordText(){
      return this.keyword[1]
    },
    rank(){
      return minifyNumberFn(this.keyword[8])
    },
    avgCPC(){
      return parseFloat(this.keyword[6]).toFixed(1)
    },
    avgDifficulty(){
      return parseInt(this.keyword[3])
    },
    totalVolume() {
      return minifyNumberFn(this.keyword[5])
    },
    totalFeaturedSnippets() {
      return Math.round(this.keyword[7])
    },
    childKeywordCount() {
      return this.keyword[4]
    },
    articleName() {
      return this.keyword[11]
    },
    collectionName() {
      return this.keyword[12]
    }
  },
  methods: {
    ngramLabelled(ngramText){
      return this.$store.state.labelNames.includes(ngramText)
    },
    ngramIncluded(ngramID){
      return this.includedNgrams.find(x => x.id == ngramID)
    },
    toggleKeywordSelection() {
      var self = this
      var event = new CustomEvent("toggle-keyword-selection", { detail: { keywordID: self.keywordID } });
      window.dispatchEvent(event);
    },
    minifyNumberFn(n){
      return minifyNumberFn(n)
    },
    toggleShowChildren() {
      var self = this

      self.showChildKeywords = !self.showChildKeywords

      if(self.showChildKeywords){
        self.fetchChildKeywords()
      }
    },
    fetchChildKeywords(){
      var self = this

      axios.get(
        `/datasets/${ self.datasetID }/keywords/${ self.keywordID }/children`,
        {
          params: {
            sort_column: 'total_volume_cache',
            sort_direction: 'desc',
          }
        }
      ).then(response => {
        self.childKeywords = response.data
      })
    },
    handleNgramClick(e, ngramID, ngramText){
      var self = this

      if(e.ctrlKey && e.shiftKey){
        self.createLabel(ngramText)
      }else if(e.ctrlKey){
        self.blockNgram(ngramID)
      }else if(e.shiftKey){
        self.ignoreNgram(ngramID)
      }else{
        self.toggleNgramIncludeSelection(ngramID, ngramText)
      }

    },
    createLabel(text){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/labels`,
        {
          label: {
            name: text
          }
        }
      )
    },
    toggleNgramIncludeSelection(ngramID, ngramText) {
      var self = this
      var event = new CustomEvent("toggle-ngram-include-selection", { detail: { ngramID: ngramID, ngramText: ngramText } });
      window.dispatchEvent(event);
    },
    toggleNgramExcludeSelection(ngramID, ngramText) {
      var self = this
      var event = new CustomEvent("toggle-ngram-exclude-selection", { detail: { ngramID: ngramID, ngramText: ngramText } });
      window.dispatchEvent(event);
    },
    blockNgram(ngramID){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ ngramID }/block`).then((response) => {
        self.disabled = true
      })
    },
    ignoreNgram(ngramID){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ ngramID }/ignore`).then((response) => {
        self.disabled = true
      })
    }
  },
  mounted() {
  }
}

</script>
