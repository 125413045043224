import consumer from "./consumer"


window.subscribeToKeywordAnalysisChannel = function(datasetID){
  consumer.subscriptions.create({
      channel: "KeywordAnalysisChannel",
      dataset_id: datasetID
    }, {
    connected() {
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      var event = new CustomEvent(data.event_type);
      window.dispatchEvent(event);
    }
  });
}
