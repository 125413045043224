<template>
  <div style="height: 100%;">
    <div style="height: calc(100% - 70px); overflow-y: scroll;" class="slim-scrollbar">
      <table>
        <thead>
          <tr>
            <th>
              Coll 1
            </th>
            <th>
              Coll 2
            </th>
            <th>
              Coll 3
            </th>
            <th
              class="cursor-pointer"
              v-on:click="updateSort('name')">
              Page
              <SortCaret v-bind:currentColumn="'name'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 70px">
              Confirm
            </th>
            <th style="width: 70px">
              Remove
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-on:click="updateSort('avg_difficulty')"
              >
              DF
              <SortCaret v-bind:currentColumn="'avg_difficulty'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-on:click="updateSort('keywords_count')"
              >
              KW
              <SortCaret v-bind:currentColumn="'keywords_count'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 40px"
              class="cursor-pointer text-center"
              v-on:click="updateSort('total_volume')"
              >
              VO
              <SortCaret v-bind:currentColumn="'total_volume'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 40px"
              class="cursor-pointer text-center"
              v-on:click="updateSort('avg_cpc')"
              >
              CPC
              <SortCaret v-bind:currentColumn="'avg_cpc'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th
              style="width: 35px"
              class="cursor-pointer text-center"
              v-on:click="updateSort('featured_snippets')"
              >
              FS
              <SortCaret v-bind:currentColumn="'featured_snippets'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
          </tr>
          <tr>
            <th>
              <input type="text" class="input" placeholder="Search" style="width: 75px" v-model="collection1Search" />
            </th>
            <th>
              <input type="text" class="input" placeholder="Search" style="width: 75px" v-model="collection2Search" />
            </th>
            <th>
              <input type="text" class="input" placeholder="Search" style="width: 75px" v-model="collection3Search" />
            </th>
            <th>
              <input type="text" class="input" placeholder="Search" v-model="pageSearch" />
            </th>
            <th colspan="7"></th>
          </tr>
        </thead>
        <ArticleRow
          v-for="article in articles"
          v-bind:datasetID="datasetID"
          v-bind:key="article.id"
          v-bind:article="article"
          v-if="checkFilters(article)"
          v-bind:class="{ 'opacity-50': fetching }"
        />
      </table>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');
import ArticleRow from './row.vue';
import SortCaret from '../../sort_caret.vue';
import RangeFilter from '../range_filter.vue';

export default {
  components: {
    ArticleRow,
    SortCaret,
    RangeFilter,
  },
  props: [
    "datasetID",
    "includedNgrams",
    "excludedNgrams",
    "mode"
  ],
  data: function() {
    return {
      page: 1,
      articles: [],
      selectAll: false,
      sortColumn: "total_volume",
      sortDirection: -1,
      collection1Search: "",
      collection2Search: "",
      collection3Search: "",
      pageSearch: "",
      fetching: false,
    }
  },
  methods: {
    checkFilters(article){
      var self = this

      if(self.collection1Search.length == 0 && self.collection2Search.length == 0 && self.collection3Search.length == 0 && self.pageSearch.length == 0){
        return true
      }

      var collectionPieces = []

      if(article.collection){
        collectionPieces = article.collection.name.split('/')
      }else{
        collectionPieces = ["", "", ""]
      }

      var passAllFilters = true

      if(self.collection1Search.length > 0){
        passAllFilters = passAllFilters && (collectionPieces[0] || "").toLowerCase().includes(self.collection1Search.toLowerCase())
      }

      if(self.collection2Search.length > 0){
        passAllFilters = passAllFilters && (collectionPieces[1] || "").toLowerCase().includes(self.collection2Search.toLowerCase())
      }

      if(self.collection3Search.length > 0){
        passAllFilters = passAllFilters && (collectionPieces[2] || "").toLowerCase().includes(self.collection3Search.toLowerCase())
      }

      if(self.pageSearch.length > 0){
        passAllFilters = passAllFilters && article.name.toLowerCase().includes(self.pageSearch.toLowerCase())
      }

      return passAllFilters
    },
    hasRangeFilters(column){
      return this.filters[column].min.length > 0 || this.filters[column].max.length > 0
    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchArticles()
    },
    fetchArticles(){
      var self = this

      self.fetching = true
      axios.get(
        `/datasets/${ self.datasetID }/articles.json`,
        {
          params: {
            mode: self.mode,
            sort_column: self.sortColumn,
            sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
          },
          paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
          }
        }
      ).then(response => {
        self.fetching = false
        self.articles = response.data
      })
    },
    handleSearchFilterUpdate(e){
      var self = this

      self.filters.include_text = e.detail.includeText
      self.filters.include_mode = e.detail.includeMode
      self.filters.exclude_text = e.detail.excludeText
      self.filters.exclude_mode = e.detail.excludeMode
      self.filters.show_labelled_articles = e.detail.showLabelledArticles

      self.page = 1
      self.fetchArticles()
    }
  },
  mounted() {
    var self = this

    self.fetchArticles()
    window.addEventListener("fetch-articles", self.fetchArticles)
  },
  beforeDestroy(){
    var self = this
    window.removeEventListener("fetch-articles", self.fetchArticles)
  }
}

</script>

<style scoped>
thead th{
  top: 0;
  background: white;
}

thead th.open-range-filter{
  background: #edf2f7;
}

thead th.active-range-filter{
  background: #90cdf4;
  color: #2c5282 !important;
}
</style>
