<template>
  <div>
    <div>
      <div class="h-6 w-6 leading-6 bg-gray-300 border text-white text-center font-bold rounded-full cursor-pointer" v-on:click="opened = !opened" v-if="assignees.length == 0">
        <i class="fa fa-plus" />
      </div>
      <div class="h-6 w-6 leading-6 bg-gray-300 border text-gray-700 text-center text-xs font-bold rounded-full cursor-pointer uppercase inline-block mr-1" v-on:click="opened = !opened" v-for="assignee in assignees">
        {{ assignee.first_name[0] }}{{ assignee.last_name[0] }}
      </div>
      <div v-if="opened">
        <div style="width: 200px;" class="absolute mt-1 bg-white rounded-mg shadow p-1">
          <div v-for="user in users" class="flex flex-row cursor-pointer p-1 hover:bg-blue-500 hover:text-white" v-on:click="toggleAssignee(user)">
            <div style="width: 30px"><i class="fa fa-check" v-if="isAssigned(user)" /></div>
            <div class="flex-grow">{{ user.first_name }} {{ user.last_name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: ['article', 'users', 'assignees'],
  methods: {
    isAssigned(user) {
      return this.assignees.find(a => a.id == user.id)
    },
    toggleAssignee(user){
      var self = this

      if(self.isAssigned(user)){
        self.removeAssignee(user)
      }else{
        self.addAssignee(user)
      }
    },
    addAssignee: function(user){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/articles/${ self.article.id }/add_assignee`,
        {
          user_id: user.id
        }
      ).then(function (response) {
        self.assignees.push(user)
      })
    },
    removeAssignee: function(user){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/articles/${ self.article.id }/remove_assignee`,
        {
          user_id: user.id
        }
      ).then(function (response) {
        var i = self.assignees.findIndex(a => a.id == user.id)

        if(i > -1){
          self.$delete(self.assignees, i)
        }
      })

    }
  },
  data: function(){
    return {
      opened: false,
      datasetID: null
    }
  },
  mounted(){
    this.datasetID = window.location.pathname.split('/')[2]
  }
}
</script>
