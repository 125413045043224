// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("sorttable")
require("table2csv")

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

import GlobalSearch from '../components/global_search/main.vue'
import ContentManager from '../components/content_manager/main.vue'
import KeywordAnalysis from '../components/keyword_analysis/main.vue'
import SerpAnalysis from '../components/serp_analysis/main.vue'

window.parameterize = require('parameterize');

var pairComputationResult = []

function findNgramSets(chain, keywords, threshold, ngram_compare_field){
  if(keywords.length < threshold){ return }

  var ngramIDs = [...new Set(keywords.map(x => x.agg_ngram_ids).flat())].filter(x => x)

  ngramIDs.sort(function compare(a,b) {
    a = ngramModel.itemsByID[a]
    b = ngramModel.itemsByID[b]
    if(!(a && b)){ return 0 }
    var a_val = a[ngram_compare_field]
    var b_val = b[ngram_compare_field]

    if (a_val < b_val){
      return -1;
    }

    if (a_val > b_val){
      return 1;
    }

    return 0;
  })

  ngramIDs.forEach(function(ngramID){
    if(chain.includes(ngramID)){ return }
    var potentialKeywords = keywords.filter(x => x.agg_ngram_ids.includes(ngramID))
    if(potentialKeywords.length >= threshold){
      findNgramSets(chain.concat([ngramID]), potentialKeywords, threshold)
    }
  })

  if(chain.length >= 2){ pairComputationResult.push(chain) }
}

window.computeSets = function(threshold, ngram_compare_field = 'total_volume'){
  pairComputationResult = []
  var ngrams = ngramModel.items

  ngrams.sort(function compare(a,b) {
    if (a[ngram_compare_field] < b[ngram_compare_field]){
      return -1;
    }

    if (a[ngram_compare_field] > b[ngram_compare_field]){
      return 1;
    }

    return 0;
  })

  ngrams.forEach(function(ngram){
    var ngramID = ngram.id
    var chain = [ngramID]
    var potentialKeywords = keywordModel.items.filter(x => x.agg_ngram_ids.includes(ngramID))
    findNgramSets(chain, potentialKeywords, threshold, ngram_compare_field)
  })

  var textKeys = {}

  pairComputationResult.sort(function(a, b) {
    var lenA = a.length
    var lenB = b.length
    if (lenA < lenB) {
      return -1;
    }
    if (lenA > lenB) {
      return 1;
    }
    return 0;
  })

  pairComputationResult.forEach(function(ngramIDs){
    keywordModel.items.forEach(function(keyword){
      if(ngramIDs.every(y => keyword.agg_ngram_ids.includes(y))){
        keyword.category_signature = ngramIDs
      }
    })
  })

  m.redraw()
}

window.initGlobalSearch = function(opts){
  const app = new Vue({
    el: '#global-search-container',
    template: '<GlobalSearch />',
    components: { GlobalSearch }
  })
}

window.initContentManager = function(opts){
  const app = new Vue({
    el: '#content-manager-container',
    template: '<ContentManager v-bind:totalQualityCheckCount="totalQualityCheckCount" />',
    data: {
      totalQualityCheckCount: opts.qualityCheckCount
    },
    components: { ContentManager }
  })
}


const store = new Vuex.Store({
  state: {
    labelNames: [],
    selectedKeywordIDs: [],
    activeNgramIDs: [],
  },
  mutations: {
    setLabelNames (state, names) {
      state.labelNames = names
    },
    setActiveNgramIDs (state, ngramIDs) {
      state.activeNgramIDs = ngramIDs
    },
    setSelectedKeywordIDs (state, keywordIDs) {
      state.selectedKeywordIDs = keywordIDs
    },
    addSelectedKeywordID (state, keywordID) {
      console.log(state.selectedKeywordIDs, keywordID)
      state.selectedKeywordIDs.push(keywordID)
    },
    removeSelectedKeywordID (state, i) {
      state.selectedKeywordIDs.splice(i, 1)
    }
  }
})

window.initKeywordAnalysis = function(opts){
  const app = new Vue({
    el: '#keyword-analysis-container',
    template: '<KeywordAnalysis id="keyword-analysis-container" v-bind:datasetID="datasetID" />',
    data: opts,
    store: store,
    components: { KeywordAnalysis }
  })

  subscribeToKeywordAnalysisChannel(opts.datasetID)
}

window.initSERPAnalysis = function(opts){
  const app = new Vue({
    el: '#serp-analysis-container',
    template: '<SerpAnalysis id="serp-analysis-container" v-bind:datasetID="datasetID" />',
    data: opts,
    store: store,
    components: { SerpAnalysis }
  })

  subscribeToKeywordAnalysisChannel(opts.datasetID)
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("css/application.scss")
