<template>
  <div class="section">
    <div class="section-header" style="padding: 0.25rem 0.25rem 0 0.25rem;">
      <div class="flex">
        <div>
          Ngrams
        </div>
        <div class="ml-auto">
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'all') }" v-on:click="setMode('all')">
            All
            <div class="subtitle">{{ allCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'active') }" v-on:click="setMode('active')">
            Active
            <div class="subtitle">{{ activeCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'queue') }" v-on:click="setMode('queue')">
            Queue
            <div class="subtitle">{{ queueCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'merged') }" v-on:click="setMode('merged')">
            Merged
            <div class="subtitle">{{ mergedCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'ignored') }" v-on:click="setMode('ignored')">
            Ignored
            <div class="subtitle">{{ ignoredCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'blocked') }" v-on:click="setMode('blocked')">
            Blocked
            <div class="subtitle">{{ blockedCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'unlabelled') }" v-on:click="setMode('unlabelled')">
            No label
            <div class="subtitle">{{ unlabelledCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex border-b">
      <div class="p-1">
        <input placeholder="Search" class="input" v-model="searchText" style="width: 150px;" v-on:change="page = 1; fetchNgrams()">
      </div>
      <div class="ml-auto p-1">
        <button class="btn btn-sm btn-primary" v-on:click="bulkCreateNgrams = true">Add Ngrams</button>
      </div>
    </div>
    <div class="p-1 border-b">
      <button class="btn btn-xs bg-red-600 inline-block" v-on:click="bulkBlockNgrams = true">Block Bulk</button>
      <button class="btn btn-xs bg-red-600 inline-block" :disabled="selectedNgramIDs.length == 0" v-on:click="blockSelected">Block Selected</button>
      <button class="btn btn-xs bg-yellow-400 inline-block" style="color: black;" v-on:click="bulkIgnoreNgrams = true">Ignore Bulk</button>
      <button class="btn btn-xs bg-yellow-400 inline-block" style="color: black;" :disabled="selectedNgramIDs.length == 0" v-on:click="ignoreSelected">Ignore Selected</button>
    </div>
    <div class="bg-white" style="height: calc(100% - 144px);">
      <div style="height: 100%; overflow-y: auto;" class="slim-scrollbar">
        <table>
          <thead>
            <th style="width: 30px" class="text-center cursor-pointer" v-on:click="selectAll ? deselectAllNgramIDs() : selectAllNgramIDs()">
              <i class="text-green-500 far fa-check-circle" v-if="selectAll" />
              <i class="text-green-500 far fa-circle" v-else="selectAll" />
            </th>
            <th class="cursor-pointer" v-on:click="updateSort('text')">
              Text
              <SortCaret v-bind:currentColumn="'text'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 30px" class="cursor-pointer text-center" v-on:click="updateSort('avg_difficulty')">
              DF
              <SortCaret v-bind:currentColumn="'avg_difficulty'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 40px" class="cursor-pointer text-center" v-on:click="updateSort('keywords_count')">
              KW
              <SortCaret v-bind:currentColumn="'keywords_count'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 40px" class="cursor-pointer text-center" v-on:click="updateSort('total_volume')">
              VO
              <SortCaret v-bind:currentColumn="'total_volume'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 40px" class="cursor-pointer text-center" v-on:click="updateSort('avg_cpc')">
              CPC
              <SortCaret v-bind:currentColumn="'avg_cpc'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 30px" class="cursor-pointer text-center" v-on:click="updateSort('featured_snippets')">
              FS
              <SortCaret v-bind:currentColumn="'featured_snippets'" v-bind:sortColumn="sortColumn" v-bind:sortDirection="sortDirection" />
            </th>
            <th style="width: 100px"></th>
          </thead>
          <NgramRow
            v-for="ngram in ngrams"
            :key="ngram[0]"
            v-bind:datasetID="datasetID"
            v-bind:ngram="ngram"
            v-bind:mode="mode"
            v-bind:selected="selectedNgramIDs.includes(ngram[0])"
            v-bind:class="{ 'opacity-50': fetching }"
          />
        </table>
      </div>
      <div class="my-1 text-center">
        <button class="btn btn-sm btn-primary text-center rounded-l-full"
          v-bind:disabled="page == 1"
          v-on:click="previousPage()"
        >
          <i class="fa fa-backward" />
        </button>
        <div class="inline-block mx-3" v-bind:class="{ 'opacity-50': fetching }">
          {{ (page - 1) * 250 + 1 }} - {{ (page - 1) * 250 + ngrams.length }} of {{ currentTabCount }}
        </div>
        <button class="btn btn-sm btn-primary text-center rounded-l-full"
          v-bind:disabled="ngrams.length < 250"
          v-on:click="nextPage()"
        >
          <i class="fa fa-forward" />
        </button>
      </div>
    </div>
    <MergeModal v-if="mergeNgramID" v-bind:ngramID="mergeNgramID" v-bind:ngramText="mergeNgramText" v-bind:datasetID="datasetID" />
    <BulkCreateModal v-if="bulkCreateNgrams" v-bind:datasetID="datasetID" />
    <BulkBlockModal v-if="bulkBlockNgrams" v-bind:datasetID="datasetID" />
    <BulkIgnoreModal v-if="bulkIgnoreNgrams" v-bind:datasetID="datasetID" />
  </div>
</template>

<script>
const axios = require('axios');
const qs = require('qs');
import NgramRow from './row.vue'
import MergeModal from './merge_modal.vue'
import BulkCreateModal from './bulk_create_modal.vue'
import BulkBlockModal from './bulk_block_modal.vue'
import BulkIgnoreModal from './bulk_ignore_modal.vue'
import SortCaret from '../../sort_caret.vue';

export default {
  components: {
    SortCaret,
    NgramRow,
    MergeModal,
    BulkCreateModal,
    BulkBlockModal,
    BulkIgnoreModal,
  },
  props: [
    "datasetID"
  ],
  data: function(){
    return {
      mode: "queue",
      searchText: "",
      page: 1,
      allCount: 0,
      queueCount: 0,
      mergedCount: 0,
      ignoredCount: 0,
      blockedCount: 0,
      unlabelledCount: 0,
      fetching: false,
      mergeNgramID: null,
      ngramCount: 0,
      ngrams: [],
      selectedNgramIDs: [],
      selectAll: false,
      sortColumn: "total_volume",
      sortDirection: -1,
      bulkCreateNgrams: false,
      bulkBlockNgrams: false,
      bulkIgnoreNgrams: false
    }
  },
  computed: {
    activeNgramIDs() {
      return this.$store.state.activeNgramIDs
    },
    activeCount() {
      return this.$store.state.activeNgramIDs.length
    },
    currentTabCount(){
      switch(this.mode){
        case 'all':
          return this.allCount
        case 'active':
          return this.activeCount
        case 'queue':
          return this.queueCount
        case 'merged':
          return this.mergedCount
        case 'ignored':
          return this.ignoredCount
        case 'blocked':
          return this.blockedCount
        case 'unlabelled':
          return this.unlabelledCount
      }
    }
  },
  methods: {
    previousPage(){
      this.page -= 1
      this.fetchNgrams()
    },
    nextPage(){
      this.page += 1
      this.fetchNgrams()
    },
    ignoreSelected(){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/ngrams/ignore_bulk`,
        {
          ngram_ids: self.selectedNgramIDs
        }
      ).then((response) => {
        self.selectedNgramIDs = []
      })
    },
    blockSelected(){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/ngrams/block_bulk`,
        {
          ngram_ids: self.selectedNgramIDs
        }
      ).then((response) => {
        self.selectedNgramIDs = []
      })
    },
    handleNgramSelection(e){
      var self = this
      var ngramID = e.detail.ngramID

      var i = self.selectedNgramIDs.indexOf(ngramID)

      if(i == -1){
        self.selectedNgramIDs.push(ngramID)
      }else{
        self.selectedNgramIDs.splice(i, 1)
        self.selectAll = false
      }
    },
    selectAllNgramIDs(){
      var self = this

      var params = {
        mode: self.mode,
        search: self.searchText,
      }

      self.selectAll = true
      axios.get(
        `/datasets/${ self.datasetID }/ngrams/select_all`,
        {
          params: params,
          paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
          }
        }
      ).then(response => {
        self.selectedNgramIDs = response.data.ngram_ids
      })
    },
    deselectAllNgramIDs() {
      var self = this

      self.selectedNgramIDs = []
      self.selectAll = false
    },
    updateSort(newColumn){
      var self = this

      if(self.sortColumn == newColumn){
        self.sortDirection = self.sortDirection * -1
      }else{
        self.sortColumn = newColumn
        self.sortDirection = 1
      }

      self.fetchNgrams()
    },
    setMode(newMode){
      var self = this

      self.mode = newMode
      self.fetchNgrams()
    },
    fetchTabCounts(){
      var self = this

      axios.get(`/datasets/${ self.datasetID }/ngrams/panel_tab_counts`).then(response => {
        self.allCount = response.data.all
        self.queueCount = response.data.queue
        self.mergedCount = response.data.merged
        self.ignoredCount = response.data.ignored
        self.blockedCount = response.data.blocked
        self.unlabelledCount = response.data.unlabelled
      })
    },
    createNgram(){
      var self = this

      var newNgramText = prompt("Enter new ngram")

      if(newNgramText.length == 0){
        return
      }

      axios.post(
        `/datasets/${ self.datasetID }/ngrams`,
        {
          text: newNgramText
        }
      )
    },
    fetchNgrams(){
      var self = this

      var params = {
        params: {
          mode: self.mode,
          page: self.page,
          search: self.searchText,
          sort_column: self.sortColumn,
          sort_direction: (self.sortDirection == 1 ? 'asc' : 'desc'),
        },
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      }

      if(self.mode == "active"){
        params.params.ngram_ids = self.activeNgramIDs.join(',')
      }

      self.fetching = true
      axios.get(
        `/datasets/${ self.datasetID }/ngrams`,
        params
      ).then(response => {
        self.fetching = false
        self.ngrams = response.data.ngrams

        axios.get(
          `/datasets/${ self.datasetID }/ngrams/counts`,
          params
        ).then(response => {
          self.ngramCount = response.data.count
        })

        self.fetchTabCounts()
      })
    },
    openNgramMergeModal(e){
      var self = this
      self.mergeNgramID = e.detail.ngramID
      self.mergeNgramText = e.detail.ngramText
    },
    closeNgramMergeModal(e){
      var self = this
      self.mergeNgramID = null
      self.mergeNgramText = null
    },
  },
  mounted() {
    var self = this

    self.fetchTabCounts()
    self.fetchNgrams()
    window.addEventListener("fetch-ngrams", self.fetchNgrams)
    window.addEventListener("update-active-ngrams", function(){
      if(self.mode == "active"){
        self.fetchNgrams()
      }
    })
    window.addEventListener("toggle-ngram-selection", self.handleNgramSelection)
    window.addEventListener("open-ngram-merge-modal", self.openNgramMergeModal)
    window.addEventListener("close-ngram-merge-modal", self.closeNgramMergeModal)
    window.addEventListener("close-bulk-create-ngrams-modal", () => { self.bulkCreateNgrams = false })
    window.addEventListener("close-bulk-block-ngrams-modal", () => { self.bulkBlockNgrams = false })
    window.addEventListener("close-bulk-ignore-ngrams-modal", () => { self.bulkIgnoreNgrams = false })
  }
}

</script>

<style scoped>
thead th{
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
}
</style>
