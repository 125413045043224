<template>
  <div>
    <div
      class="absolute"
      style="top: 0; left: 0; width: 100%; height: 100%; z-index: 999; cursor: default;"
      v-on:click.stop.prevent="broadcastRangeUpdate()"
      v-on:contextmenu.stop.prevent="broadcastRangeUpdate()"
    >
    </div>
    <div class="absolute bg-gray-200 shadow p-2" style="z-index: 99999; margin-left: -65px; clip: unset" v-on:click.stop.prevent>
      <div class="flex" style="width: 150px">
        <div><input autofocus type="text" class="input" v-model="min" v-on:keyup.enter="broadcastRangeUpdate()" /></div>
        <div class="p-1">to</div>
        <div><input autofocus type="text" class="input" v-model="max" v-on:keyup.enter="broadcastRangeUpdate()" /></div>
      </div>
      <div style="font-size: 10px; color: black;" class="text-center pt-2 cursor-pointer" v-on:click="clearRangeFilter()">
        CLEAR
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "column",
    "min",
    "max",
  ],
  methods: {
    clearRangeFilter() {
      var self = this

      self.min = ""
      self.max = ""

      self.broadcastRangeUpdate()
    },
    broadcastRangeUpdate(){
      var self = this

      var event = new CustomEvent("range-filter-update", {
        detail: {
          column: self.column,
          min: self.min,
          max: self.max,
        }
      });

      window.dispatchEvent(event);
    }
  }
}
</script>
