<template>
  <tbody>
    <tr>
      <td>
        <input v-if="editing" type="text" class="input" v-model="label.name" v-on:change="updateName()" />
        <div v-else class="p-1">
          {{ label.name }}
        </div>
      </td>
      <td class="text-center">{{ parseInt(label.avg_difficulty_cache || 0) }}</td>
      <td class="text-center">{{ minifyNumber(label.total_keywords_cache) }}</td>
      <td class="text-center">{{ minifyNumber(label.total_volume_cache) }}</td>
      <td class="text-center">{{ parseFloat(label.avg_cpc_cache || 0).toFixed(1) }}</td>
      <td class="text-center">{{ minifyNumber(label.featured_snippets_cache) }}</td>
      <td class="text-right">
        <div class="action-icons text-xs whitespace-no-wrap px-1">
          <div v-on:click="toggleEdit()" title="Edit" class="inline-block rounded-full text-white cursor-pointer text-center bg-blue-600 hover:bg-blue-800">
            <i class="fa fa-pencil" />
          </div>
          <div v-on:click="remove()" title="Remove" class="inline-block rounded-full text-white cursor-pointer text-center bg-red-600 hover:bg-red-800">
            <i class="fa fa-trash" />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
const axios = require('axios');
import minifyNumberFn from '../../../mithril/minify_number.js';

export default {
  components: {
  },
  props: [
    "label",
    "datasetID",
  ],
  data: function(){
    return {
      editing: false
    }
  },
  methods: {
    minifyNumber(n){
      return minifyNumberFn(n)
    },
    toggleEdit(){
      var self = this
      self.editing = !self.editing
    },
    updateName(){
      var self = this
      axios.patch(
        `/datasets/${ self.datasetID }/labels/${ self.label.id}`,
        {
          label: {
            name: self.label.name
          }
        }
      ).then(response => {
        self.label.name = response.data.name
        self.editing = false
      })
    },
    remove(){
      var self = this
      axios.delete(`/datasets/${ self.datasetID }/labels/${ self.label.id}`)
    }
  }
}
</script>

<style scoped>
.action-icons div{
  font-size: 10px;
  line-height: 18px;
  font-weight: bold;
  margin-left: 2px;
}
.action-icons .rounded-full{
  width: 18px;
  height: 18px;
}
</style>
