<template>
  <div class="section">
    <div class="section-header" style="padding-bottom: 0">
      <div class="flex">
        <div>
          Keywords/Pages
        </div>
        <div class="ml-auto">
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'keywords-all') }" v-on:click="setMode('keywords-all')">
            All Keywords
            <div class="subtitle">{{ allKeywordCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'keywords-unassigned') }" v-on:click="setMode('keywords-unassigned')">
            Assign
            <div class="subtitle">{{ unassignedKeywordCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'articles-unconfirmed') }" v-on:click="setMode('articles-unconfirmed')">
            Confirm
            <div class="subtitle">{{ unconfirmedArticleCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'articles-confirmed') }" v-on:click="setMode('articles-confirmed')">
            Done
            <div class="subtitle">{{ confirmedArticleCount }}</div>
          </div>
          <div class="nav-tabs" v-bind:class="{ active: (mode == 'confirmed') }" v-on:click="setMode('confirmed')">
            Publish
            <div class="subtitle">{{ collectionCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100%;">
      <KeywordFilterUI
        v-if="['keywords-all', 'keywords-unassigned'].includes(mode)"
        v-bind:datasetID="datasetID"
        v-bind:includedNgrams="includedNgrams"
        v-bind:excludedNgrams="excludedNgrams"
      />
      <KeywordList
        v-if="mode == 'keywords-all'"
        v-bind:datasetID="datasetID"
        v-bind:mode="mode"
        v-bind:includedNgrams="includedNgrams"
        v-bind:excludedNgrams="excludedNgrams"
        :key="'keywords_all'"
      />
      <KeywordList
        v-else-if="mode == 'keywords-unassigned'"
        v-bind:datasetID="datasetID"
        v-bind:mode="mode"
        v-bind:includedNgrams="includedNgrams"
        v-bind:excludedNgrams="excludedNgrams"
        :key="'keywords_unassigned'"
      />
      <ArticlePanel
        v-else-if="mode == 'articles-unconfirmed'"
        v-bind:datasetID="datasetID"
        v-bind:mode="mode"
        :key="'articles_confirmed'"
      />
      <ArticlePanel
        v-else-if="mode == 'articles-confirmed'"
        v-bind:datasetID="datasetID"
        v-bind:mode="mode"
        :key="'articles_unconfirmed'"
      />
    </div>
  </div>
</template>

<script>
const axios = require('axios');

import ArticlePanel from './articles/panel.vue';
import KeywordList from './keywords/list.vue';
import KeywordFilterUI from './keywords/filter_ui.vue';

export default {
  components: {
    ArticlePanel,
    KeywordList,
    KeywordFilterUI,
  },
  props: [
    "datasetID"
  ],
  data: function() {
    return {
      mode: "keywords-unassigned",
      includedNgrams: [],
      excludedNgrams: [],
      allKeywordCount: 0,
      unassignedKeywordCount: 0,
      unconfirmedArticleCount: 0,
      confirmedArticleCount: 0,
      collectionCount: 0
    }
  },
  methods: {
    setMode(newMode){
      var self = this
      self.mode = newMode

      var event = new CustomEvent("center-panel-mode-change", { detail: { mode: self.mode } });
      window.dispatchEvent(event);
    },
    fetchTabCounts(){
      var self = this

      axios.get(`/datasets/${ self.datasetID }/keyword_panel_tab_counts`).then(response => {
        self.allKeywordCount = response.data.all_keywords
        self.unassignedKeywordCount = response.data.unassigned_keywords
        self.unconfirmedArticleCount = response.data.unconfirmed_articles
        self.confirmedArticleCount = response.data.confirmed_articles
        self.collectionCount = response.data.collections
      })
    },
    handleToggleNgramInclude(e){
      var self = this

      var i = self.includedNgrams.findIndex(x => e.detail.ngramID == x.id)

      if(i == -1){
        self.includedNgrams.push({
          id: e.detail.ngramID,
          text: e.detail.ngramText
        })
      }else{
        self.includedNgrams.splice(i, 1)
      }

      var event = new CustomEvent("fetch-keywords");
      window.dispatchEvent(event);
    },
    handleToggleNgramExclude(e){
      var self = this

      var i = self.excludedNgrams.findIndex(x => e.detail.ngramID == x.id)

      if(i == -1){
        self.excludedNgrams.push({
          id: e.detail.ngramID,
          text: e.detail.ngramText
        })
      }else{
        self.excludedNgrams.splice(i, 1)
      }

      var event = new CustomEvent("fetch-keywords");
      window.dispatchEvent(event);
    }
  },
  mounted() {
    this.fetchTabCounts()
    window.addEventListener("fetch-keywords", this.fetchTabCounts)
    window.addEventListener("fetch-articles", this.fetchTabCounts)
    window.addEventListener("toggle-ngram-include-selection", this.handleToggleNgramInclude)
    window.addEventListener("toggle-ngram-exclude-selection", this.handleToggleNgramExclude)
  },
  beforeDestroy(){
    window.removeEventListener("toggle-ngram-include-selection", this.handleToggleNgramInclude)
    window.removeEventListener("toggle-ngram-exclude-selection", this.handleToggleNgramExclude)
  }
}

</script>
