<template>
  <tr>
    <td>{{ serp.collection_name }}</td>
    <td>{{ serp.article_name }}</td>
    <td>{{ serp.domain_name }}</td>
    <td>{{ serp.title }}</td>
    <td><div class="truncate" style="width: 350px"><a v-bind:href="serp.link" v-bind:title="serp.link">{{ serp.link }}</a></div></td>
    <td>{{ serp.age_in_months }}</td>
    <td>{{ serp.position }}</td>
  </tr>
</template>

<script>
const axios = require('axios');

export default {
  props: [
    "datasetID",
    "serp",
  ]
}
</script>
