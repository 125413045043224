<template>
  <tbody>
    <tr v-bind:class="{ 'hidden': disabled }">
      <td class="text-center cursor-pointer" v-on:click="toggleNgramSelection()">
        <i class="text-green-500 far fa-check-circle" v-if="selected" />
        <i class="text-green-500 far fa-circle" v-else />
      </td>
      <td>
        <div
          class="inline-block truncate cursor-pointer"
          style="max-width: 300px"
          v-bind:title="ngramText"
          v-on:click="toggleNgramIncludeSelection()"
          v-on:contextmenu.stop.prevent="toggleNgramExcludeSelection()"
        >
          {{ ngramText }}
        </div>
      </td>
      <td class="text-center">{{ avgDifficulty }}</td>
      <td class="text-center">{{ totalKeywords }}</td>
      <td class="text-center">{{ totalVolume }}</td>
      <td class="text-center">{{ avgCPC }}</td>
      <td class="text-center">{{ totalFeaturedSnippets }}</td>
      <td>
        <div class="action-icons text-xs whitespace-no-wrap">

          <div v-if="mode == 'blocked'" v-on:click="unblock()" title="Block" class="inline-block text-white cursor-pointer text-center ml-1 px-2 bg-red-600 hover:bg-red-800">
            Unblock
          </div>

          <div v-if="mode == 'ignored'" v-on:click="unignore()" title="Block" class="inline-block cursor-pointer text-center text-black ml-1 px-2 bg-yellow-400 hover:bg-yellow-600">
            Unignore
          </div>

          <div v-if="mode != 'blocked' && mode != 'ignored'">
            <div v-on:click="block()" title="Block" class="inline-block rounded-full text-white cursor-pointer text-center ml-1 bg-red-600 hover:bg-red-800">
              B
            </div>

            <div v-on:click="ignore()" title="Ignore" class="inline-block rounded-full cursor-pointer text-center text-black ml-1 bg-yellow-400 hover:bg-yellow-600">
              I
            </div>
            <div v-on:click="startMerge()" title="Group/Merge" class="inline-block rounded-full text-white bg-blue-600 cursor-pointer text-center ml-1 ml-1 hover:bg-blue-800">
              M
            </div>
            <div title="Label" v-on:click="createLabel(ngramText)" v-if="!labelExists(ngramText)" class="inline-block rounded-full text-white bg-blue-600 cursor-pointer text-center ml-1 ml-1 hover:bg-blue-800">
              L
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
const axios = require('axios');
import minifyNumberFn from '../../../mithril/minify_number.js';

export default {
  components: {
  },
  props: [
    "ngram",
    "mode",
    "datasetID",
    "selected",
  ],
  data: function() {
    return {
      showChildNgrams: false,
      disabled: false,
      childNgrams: []
    }
  },
  computed: {
    // Ngram data is sent as an array like so
    //
    // 0 => id
    // 1 => text
    // 2 => avg difficulty
    // 3 => keywords
    // 4 => total volume
    // 5 => avg cpc
    // 6 => featured snippets
    // 7 => ignored
    // 8 => blocked
    // 9 => merged

    ngramID(){
      return this.ngram[0]
    },
    ngramText(){
      return this.ngram[1]
    },
    avgDifficulty(){
      return parseInt(this.ngram[2])
    },
    totalKeywords(){
      return parseInt(this.ngram[3])
    },
    totalVolume() {
      return minifyNumberFn(this.ngram[4])
    },
    avgCPC(){
      return parseFloat(this.ngram[5]).toFixed(1)
    },
    totalFeaturedSnippets() {
      return this.ngram[6]
    },
    ngramIgnored(){
      return this.ngram[7]
    },
    ngramBlocked(){
      return this.ngram[8]
    },
    ngramMerged(){
      return this.ngram[9]
    },
  },
  methods: {
    toggleNgramSelection() {
      var event = new CustomEvent("toggle-ngram-selection", { detail: { ngramID: this.ngramID } });
      window.dispatchEvent(event);
    },
    minifyNumberFn(n){
      return minifyNumberFn(n)
    },
    toggleNgramIncludeSelection() {
      var event = new CustomEvent("toggle-ngram-include-selection", { detail: { ngramID: this.ngramID, ngramText: this.ngramText } });
      window.dispatchEvent(event);
    },
    toggleNgramExcludeSelection() {
      var event = new CustomEvent("toggle-ngram-exclude-selection", { detail: { ngramID: this.ngramID, ngramText: this.ngramText } });
      window.dispatchEvent(event);
    },
    startMerge(){
      var event = new CustomEvent("open-ngram-merge-modal", { detail: { ngramID: this.ngramID, ngramText: this.ngramText } });
      window.dispatchEvent(event);
    },
    block(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/block`).then((response) => {
        self.disabled = true
      })
    },
    unblock(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/unblock`).then((response) => {
        self.disabled = true
      })
    },
    ignore(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/ignore`).then((response) => {
        self.disabled = true
      })
    },
    unignore(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/unignore`).then((response) => {
        self.disabled = true
      })
    },
    labelExists(text){
      var self = this

      return self.$store.state.labelNames.includes(text)
    },
    createLabel(text){
      var self = this

      axios.post(
        `/datasets/${ self.datasetID }/labels`,
        {
          label: {
            name: text
          }
        }
      )
    }
  },
  mounted() {
  }
}

</script>

<style scoped>
.action-icons div{
  font-size: 11px;
  line-height: 18px;
  font-weight: bold;
}
.action-icons .rounded-full{
  width: 18px;
  height: 18px;
}

td{
  font-size: 11px !important;
}
</style>
