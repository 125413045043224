<template>
  <div>
    <div class="modal-overlay" v-on:click="closeModal()">
      <div class="modal-container p-2" style="width: 1000px;" v-on:click.stop.prevent>
        <div class="flex">
          <div>Group/Merge: {{ ngramText }}</div>
          <div class="ml-auto"><button class="btn btn-sm btn-primary" v-on:click="markAsMerged">Mark as merged</button></div>
        </div>
        <div class="grid col-gap-1 grid-cols-3 mt-3">
          <div class="font-medium uppercase mt-2">Children</div>
          <div class="font-medium uppercase mt-2">Similar Words</div>
          <input class="input mb-1" type="text" placeholder="Search" v-model="searchText" v-on:change="search()" />
          <div>
            <div class="slim-scrollbar border" style="height: 275px; overflow-y: auto">
              <div class="p-1 cursor-pointer hover:bg-gray-200"
                v-for="child in children"
                :key="child.id"
                v-on:click="unassignParent(child.id)"
              >{{ child.text }}</div>
            </div>
          </div>
          <div>
            <div class="slim-scrollbar border" style="height: 275px; overflow-y: auto">
              <div class="p-1 cursor-pointer hover:bg-gray-200"
                v-for="potentialChild in potentialChildren"
                :key="potentialChild.id"
                v-on:click="assignParent(potentialChild.id)"
              >{{ potentialChild.text }}</div>
            </div>
          </div>
          <div>
            <div class="slim-scrollbar border" style="height: 275px; overflow-y: auto">
              <div class="p-1 cursor-pointer hover:bg-gray-200"
                v-for="result in searchResults"
                :key="result.id"
                v-on:click="assignParent(result.id)"
              >{{ result.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default{
  props: [
    'ngramID',
    'ngramText',
    'datasetID',
  ],
  data: () => {
    return {
      searchText: "",
      children: [],
      searchResults: [],
      potentialChildren: [],
    }
  },
  methods: {
    closeModal(){
      var event = new CustomEvent("close-ngram-merge-modal");
      window.dispatchEvent(event);
    },
    markAsMerged(){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/mark_as_merged`)
      self.closeModal()
    },
    search(){
      var self = this

      axios.get(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/search`, {
        params: {
          query: self.searchText
        }
      }).then((response) => {
        self.searchResults = response.data
      })
    },
    assignParent(childNgramID){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ childNgramID }/assign_parent`, {
        parent_ngram_id: self.ngramID
      })
    },
    unassignParent(childNgramID){
      var self = this
      axios.post(`/datasets/${ self.datasetID }/ngrams/${ childNgramID }/unassign_parent`)
    },
    fetchChildNgrams(){
      var self = this

      axios.get(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/children`).then((response) => {
        self.children = response.data
      })
    },
    fetchPotentialChildren(){
      var self = this

      axios.get(`/datasets/${ self.datasetID }/ngrams/${ self.ngramID }/potential_children`, {
        params: {
          similarity: 0.5
        }
      }).then((response) => {
        self.potentialChildren = response.data
      })
    }
  },
  mounted(){
    this.fetchChildNgrams()
    this.fetchPotentialChildren()

    window.addEventListener("fetch-ngrams", this.fetchChildNgrams)
    window.addEventListener("fetch-ngrams", this.fetchPotentialChildren)
  },
  beforeDestroy(){
    var self = this

    window.removeEventListener("fetch-ngrams", this.fetchChildNgrams)
    window.removeEventListener("fetch-ngrams", this.fetchPotentialChildren)
  }
}
</script>
